import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Grid, Typography, Stack } from "@mui/material";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { APITypes, ECluster } from "utils/api.types";
import { fetchPositionsWithCluster } from "utils/fetchingFunctions";
import { capitalize, cleanLocation } from "./JobCard";
import { colors } from "@components/Theme/colors";

export interface JobsPreviewProps {
    block: APITypes.Components.ContentBlocks.JobsPreview;
    slug: string;
    localizations: APITypes.Localization[];
}

export const JobsPreview = ({ block }: JobsPreviewProps) => {
    const router = useRouter();
    const {
        title,
        button,
        jobCardPreview,
        forCluster,
        backgroundColor,
        backgroundCardColor,
    } = block;

    const [positions, setPositions] = useState<APITypes.Components.Job[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetchPositionsWithCluster(
                router.locale,
                0,
                3,
                forCluster,
            );
            setPositions(res.data);
        };
        fetchData();
    }, [router.locale, forCluster]);

    if (!positions || (positions && positions.length === 0)) return null;
    return (
        <Box
            py={{ xs: 10, background: backgroundColor ?? colors.white.main }}
            id="jobs-preview"
        >
            <Container maxWidth="xl">
                <Box mb={6}>
                    <Typography variant="h2">{title}</Typography>
                </Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={4}
                >
                    {(positions || []).map((position, i) => (
                        <PreviewJobCard
                            key={i}
                            id={position.id}
                            title={position.title}
                            location={position.location}
                            subTitle={position.subTitle}
                            slug={position.slug}
                            button={button}
                            backgroundCardColor={backgroundCardColor}
                        />
                    ))}
                    <PreviewJobCard
                        isLastCard={true}
                        title={jobCardPreview.title}
                        subTitle={jobCardPreview.subTitle}
                        button={jobCardPreview.button}
                        forCluster={forCluster}
                        backgroundCardColor={backgroundCardColor}
                    />
                </Grid>
            </Container>
        </Box>
    );
};
export default JobsPreview;

interface PreviewJobCardProps {
    isLastCard?: boolean;
    title: string;
    subTitle: string;
    button: APITypes.Components.Button;
    forCluster?: ECluster;
    id?: string;
    slug?: string;
    location?: string;
    backgroundCardColor?: string;
}

const PreviewJobCard = ({
    isLastCard,
    location,
    title,
    subTitle,
    button,
    slug,
    forCluster,
    backgroundCardColor,
}: PreviewJobCardProps) => {
    const link = useMemo(() => {
        let link = button.href;
        if (slug) link = `${link}/${slug}`;
        if (forCluster) link = `${link}?cluster=${forCluster}`;
        return link;
    }, [forCluster, button, slug]);

    return (
        <Grid container direction={"column"} item xs={12} md={3}>
            <Stack
                py={8}
                px={4}
                sx={{
                    backgroundColor: isLastCard
                        ? colors.secondary.main
                        : backgroundCardColor ?? colors.greyColor.light,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flex: 1,
                    height: "100%",
                }}
            >
                <Stack
                    gap={2}
                    sx={{
                        flex: 1,
                        justifyContent: isLastCard ? "center" : "start",
                        alignItems: isLastCard ? "center" : "start",
                    }}
                >
                    {location != null && (
                        <Typography
                            sx={{
                                color: colors.secondary.main,
                            }}
                        >
                            {capitalize(cleanLocation(location))}
                        </Typography>
                    )}
                    <Typography
                        variant="h3"
                        style={{
                            wordBreak: "break-word",
                            color: isLastCard
                                ? colors.white.main
                                : colors.primary.main,
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        align={isLastCard ? "center" : "left"}
                        sx={{
                            color: isLastCard
                                ? colors.white.main
                                : colors.black.main,
                            wordBreak: "break-word",
                        }}
                    >
                        {subTitle}
                    </Typography>
                </Stack>
                <Stack
                    flexDirection={"row"}
                    pt={4}
                    justifyContent={isLastCard ? "center" : "left"}
                >
                    <Button
                        size="large"
                        variant="contained"
                        component={NextLink}
                        href={link}
                        color={isLastCard ? "green" : "greyColor"}
                        sx={{
                            py: 1,
                        }}
                        endIcon={<ArrowForwardIcon />}
                    >
                        {button.title}
                    </Button>
                </Stack>
            </Stack>
        </Grid>
    );
};
