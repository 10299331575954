import { colors } from "@components/Theme/colors";

export const textFieldStyle = {
    marginBottom: "20px",
    border: 0,
    borderRadius: 0,
    fontSize: { xs: "1rem", md: "1.5rem" },
    ".MuiInputBase-input": {
        fontSize: { xs: "1rem", md: "1.5rem" },
        fontWeight: 500,
        fontFamily: "Goodall Pro",
        fontStyle: "italic",
        color: colors.primary.main,
    },
    input: {
        backgroundColor: colors.white.main,
        "&::placeholder": {
            color: colors.primary.main,
            backgroundColor: colors.white.main,
            fontSize: { xs: "1rem", md: "1.5rem" },
            fontWeight: 500,
            fontFamily: "Goodall Pro",
            fontStyle: "italic",
            opacity: 1,
        },
    },
    "& .MuiInputBase-adornedEnd ": {
        backgroundColor: colors.white.main,

        "&::placeholder": {
            color: colors.white.main,
            backgroundColor: colors.white.main,
        },
    },
    "& .MuiSvgIcon-root": {
        width: "40px",
        height: "40px",
        padding: "4px",
        color: colors.white.main,
        backgroundColor: colors.secondary.main,
    },

    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.white.main,
    },
    "& .MuiInputLabel-formControl.Mui-focused": {
        color: colors.white.main,
    },

    borderColor: colors.primary.main,
    "& fieldset": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        border: 0,
    },

    "& .MuiInputBase-multiline": {
        backgroundColor: colors.white.main,
        "&::placeholder": {
            color: colors.primary.main,
        },
    },
    "& .MuiSelect-select ": {
        backgroundColor: colors.white.main,
        "&::placeholder": {
            color: colors.primary.main,
        },
    },
};

export const paginationStyle = {
    "& .MuiPaginationItem-root": {
        border: `1.5px solid ${colors.secondary.main}`,
        color: "primary.main",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: colors.secondary.main,
        color: colors.white.main,
    },
};
