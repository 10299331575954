import LandingPageHero from "@components/blocks/LandingPageHero/LandingPageHero";
import { BreadCrumbs } from "@components/BreadCrumbs/BreadCrumbs";
import SubTitleBiggest from "@components/typography/SubTitleBiggest";
import TitleBiggest from "@components/typography/TitleBiggest";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import { Image, Markdown } from "components";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { APITypes } from "utils/api.types";
import { fetchPositionsWithCluster } from "utils/fetchingFunctions";

export interface HeroITClusterProps {
    block: APITypes.Components.ContentBlocks.HeroITCluster;
}

const HeroITCluster = ({ block }: HeroITClusterProps) => {
    const router = useRouter();
    const { hero } = block;

    const [positions, setPositions] = useState<APITypes.Components.Job[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetchPositionsWithCluster(
                "nl",
                0,
                3,
                block.hero.forCluster,
            );
            setPositions(res.data);
        };
        fetchData();
    }, [block.hero.forCluster]);

    return (
        <LandingPageHero
            block={
                {
                    ...block.hero,
                    heroTitle: hero.heroSquareBlock.title,
                    heroSquareBlock: {
                        ...hero.heroSquareBlock,
                        subTitle: hero.heroSquareBlock.subTitle,
                    },
                } as any
            }
        />

        ///OLD UI
        // <Box pb={12} sx={{ backgroundColor: "#F3F3F3" }}>
        //     <Box>
        //         <Image
        //             image={hero.heroImage}
        //             alt={hero.heroImage?.name}
        //             layout={"responsive"}
        //         />
        //     </Box>
        //     <Container maxWidth="lg">
        //         <Grid
        //             sx={{
        //                 marginTop: {
        //                     lg: "-280px",
        //                     md: "-160px",
        //                     sm: "-100px",
        //                     xs: "-60px",
        //                 },
        //                 width: "100%",
        //                 marginRight: "auto",
        //                 marginLeft: "auto",
        //             }}
        //             container
        //             direction="column"
        //             alignItems="center"
        //             justifyContent="center"
        //         >
        //             <Grid item xs={2}>
        //                 <Card
        //                     variant="outlined"
        //                     sx={{
        //                         boxShadow:
        //                             " 0px 22px 44px rgba(0, 0, 0, 0.07);",
        //                     }}
        //                 >
        //                     <Box
        //                         sx={{
        //                             margin: {
        //                                 lg: 6,
        //                                 xs: 2,
        //                             },
        //                         }}
        //                     >
        //                         <CardContent>
        //                             <Box sx={{ mb: "32px" }}>
        //                                 <BreadCrumbs />
        //                             </Box>
        //                             <TitleBiggest
        //                                 titleSize={
        //                                     hero.heroSquareBlock.titleSize
        //                                 }
        //                                 text={hero.heroSquareBlock.title}
        //                             />
        //                             <SubTitleBiggest
        //                                 text={hero.heroSquareBlock.subTitle}
        //                             />
        //                             <Box sx={{ m: 6 }} />
        //                             <Typography
        //                                 component={"div"}
        //                                 sx={{
        //                                     fontSize: {
        //                                         lg: "21px",
        //                                         xs: "18px",
        //                                     },
        //                                     fontWeight: 400,
        //                                     "& a": {
        //                                         color: "#3EA4DC",
        //                                         textDecoration: "none",
        //                                     },
        //                                 }}
        //                             >
        //                                 <Markdown
        //                                     content={
        //                                         hero.heroSquareBlock.description
        //                                     }
        //                                 />
        //                             </Typography>
        //                         </CardContent>
        //                         <Box sx={{ m: 4 }} />
        //                         {hero.heroSquareBlock?.button && (
        //                             <CardActions
        //                                 sx={{
        //                                     justifyContent: {
        //                                         xs: "center",
        //                                         lg: "left",
        //                                     },
        //                                 }}
        //                             >
        //                                 <Button
        //                                     variant="contained"
        //                                     size="large"
        //                                     sx={{
        //                                         fontSize: "21px",
        //                                         fontWeight: 700,
        //                                         textTransform: "none",
        //                                         "&.MuiButton-contained": {
        //                                             backgroundColor:
        //                                                 hero.heroSquareBlock
        //                                                     .button.hexColor,
        //                                         },
        //                                         borderRadius: 0,
        //                                     }}
        //                                 >
        //                                     <Link
        //                                         href={
        //                                             hero.heroSquareBlock.button
        //                                                 .href
        //                                         }
        //                                         underline="none"
        //                                         sx={{
        //                                             color: "#fff",
        //                                             "&:hover": {
        //                                                 color: "#3EA4DC",
        //                                             },
        //                                         }}
        //                                     >
        //                                         {
        //                                             hero.heroSquareBlock.button
        //                                                 .title
        //                                         }
        //                                     </Link>
        //                                 </Button>
        //                             </CardActions>
        //                         )}
        //                     </Box>
        //                 </Card>
        //             </Grid>
        //         </Grid>
        //     </Container>
        //     {positions.length > 0 && block.blueLink && (
        //         <Container
        //             maxWidth={false}
        //             sx={{
        //                 display: {
        //                     xs: "none",
        //                     md: "block",
        //                 },
        //                 maxWidth: "1300px",
        //             }}
        //         >
        //             <Box sx={{ position: "relative" }}>
        //                 <Box
        //                     component="img"
        //                     style={{
        //                         position: "absolute",
        //                         zIndex: 2,
        //                         top: -100,
        //                         right: 0,
        //                         width: "235px",
        //                         height: "213px",
        //                     }}
        //                     alt="blob"
        //                     src="/Blob.svg"
        //                 />
        //                 <Box
        //                     sx={{
        //                         fontFamily: " Proxima Soft",
        //                         fontSize: "21px",
        //                         fontWeight: 700,
        //                         textAlign: "center",
        //                         width: "182px",
        //                         position: "absolute",
        //                         zIndex: 2,
        //                         top: -60,
        //                         right: 20,
        //                         color: "white",
        //                         a: {
        //                             color: "#EE3D96",
        //                         },
        //                     }}
        //                 >
        //                     <Markdown
        //                         content={block.blueLink}
        //                         isOpenLinkNewTab={false}
        //                     />
        //                 </Box>
        //             </Box>
        //         </Container>
        //     )}
        // </Box>
    );
};
export default HeroITCluster;
