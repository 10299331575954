import { colors } from "@components/Theme/colors";
import ContactForm from "@components/VacatureDetail/VacatureDetailContactBlock/ContactForm";
import {
    CONSULT_FR,
    CONSULT_NL,
    INTERNSHIP_FR,
    INTERNSHIP_NL,
    SPONTANEOUS_APPLICATION,
} from "@components/VacatureDetail/VacatureDetailDescriptionBlock/VacatureDetailDescriptionBlock";
import { Box, Container, Grid } from "@mui/material";
import { useRouter } from "next/router";
import { APITypes } from "utils/api.types";

interface VacatureFormProps {
    block: APITypes.Components.ContentBlocks.VacatureForm;
    isScriptLoaded: boolean;
}

const VacatureForm = ({ block, isScriptLoaded }: VacatureFormProps) => {
    const router = useRouter();

    //Another dirty, dirty hack.
    const getJobId = () => {
        if (router.asPath.includes("stages")) {
            return router.locale === "fr-FR" ? INTERNSHIP_FR : INTERNSHIP_NL;
        }
        if (router.asPath.includes("hr-talent")) {
            return router.locale === "fr-FR" ? CONSULT_FR : CONSULT_NL;
        }
        return SPONTANEOUS_APPLICATION;
    };

    return (
        <Box
            id="form"
            sx={{
                py: { xs: 10, md: 20 },
                backgroundColor: colors.greyColor.light,
            }}
        >
            <Container maxWidth="lg">
                <Grid item xs={12}>
                    <ContactForm
                        form={block.vacatureForm}
                        isScriptLoaded={isScriptLoaded}
                        jobsId={getJobId()}
                    />
                </Grid>
            </Container>
        </Box>
    );
};

export default VacatureForm;
