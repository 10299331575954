import { Button } from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { APITypes } from "utils/api.types";
import React from "react";
import { colors } from "@components/Theme/colors";
import NextLink from "next/link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface MovingBarProps {
    block?: APITypes.Components.ContentBlocks.MovingBar;
}

const MovingBar = ({ block }: MovingBarProps) => {
    if (!block) {
        return null;
    }
    return (
        <Paper
            sx={{
                width: "100%",
                position: "fixed",
                bottom: 0,
                boxShadow: "-4px 4px 25px 0px rgba(0, 0, 0, 0.10)",
                height: "135px",
                backgroundColor: colors.primary.main,
                display: "flex",
            }}
            component="footer"
            square
            variant="elevation"
        >
            <Container
                maxWidth="lg"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                }}
            >
                <Typography
                    variant="h2"
                    style={{
                        color: colors.white.main,
                    }}
                >
                    {block.coloredTitle} {block.title}
                </Typography>
                <Button
                    component={NextLink}
                    variant="contained"
                    color="green"
                    href={block.button.href}
                    endIcon={<ArrowForwardIcon />}
                >
                    {block.button.title}
                </Button>
            </Container>
        </Paper>
    );
};

export default MovingBar;
