import { colors } from "@components/Theme/colors";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
    default as ExpandLess,
    default as ExpandLessIcon,
} from "@mui/icons-material/ExpandLess";
import {
    default as ExpandMore,
    default as ExpandMoreIcon,
} from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { Collapse, Container, Icon, Link, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Toolbar from "@mui/material/Toolbar";
import { Image } from "components";
import NextLink from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { APITypes } from "utils/api.types";
import { setNextLocaleCookieTo } from "utils/localization";

interface NavigationBarProps {
    block: APITypes.Components.ContentBlocks.NavigationBar;
    slug: string;
    localizations: APITypes.Localization[];
}

const drawerButtonStyle = {
    width: "100%",
    textDecoration: "none",
    color: "white",
};

export const FR_LOCALE = "fr-FR";
export const FR_LABEL = "FR";
export const EN_LOCALE = "en";

const isITpage = (href: string) => {
    return href.includes("it") || href.includes("IT");
};
export const getLocaleLabel = (
    item: APITypes.Components.NavigationMenu,
    locale?: string,
) => {
    if (!item.links) return;
    const found = item.links.find((child) => child.href === locale);
    if (found) return found.name === FR_LOCALE ? FR_LABEL : found.name;
    return "NL";
};
const NavigationBar = ({ block, slug, localizations }: NavigationBarProps) => {
    const router = useRouter();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const [anchorEl, setAnchorEl] = React.useState<{
        [key: string]: HTMLElement;
    } | null>(null);

    const onSelectOtherLanguage = React.useCallback(
        (language: string) => {
            let lang = language;
            if (language === FR_LABEL) lang = FR_LOCALE;
            let updatedSlug = (
                localizations.find(
                    (localization) => localization.locale === lang,
                )?.slug ?? slug
            )
                .split("~")
                .join("/");

            updatedSlug = updatedSlug === "index" ? "" : updatedSlug;

            //Very dirty fix, but it works
            updatedSlug =
                updatedSlug.split("/")[1] === "detail"
                    ? updatedSlug.split("/")[0]
                    : updatedSlug;

            const updatedPath =
                updatedSlug != null ? `/${lang}/${updatedSlug}` : lang;
            setNextLocaleCookieTo(lang);

            router.push(updatedPath, updatedPath, {
                locale: lang ?? undefined,
            });
        },
        [localizations, router, slug],
    );

    const handleMenu = (
        event: React.MouseEvent<HTMLElement>,
        index: string,
    ) => {
        setAnchorEl({ [index]: event.currentTarget });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar
                component="nav"
                sx={{ background: "#fff", boxShadow: "unset" }}
                position="sticky"
            >
                <Container maxWidth="xl">
                    <Toolbar
                        disableGutters
                        sx={{
                            height: "85px",
                            width: "100%",
                        }}
                    >
                        <Box
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: {
                                    width: "121px",
                                    height: "53px",
                                },
                            }}
                        >
                            <Link
                                component={NextLink}
                                href="/"
                                locale={
                                    router.locale === EN_LOCALE
                                        ? "fr-FR"
                                        : router.locale
                                }
                            >
                                <Icon
                                    sx={{
                                        ml: 0,
                                        width: "121px",
                                        height: "53px",
                                        textAlign: "center",
                                    }}
                                >
                                    <Image
                                        alt={block.logo.name}
                                        image={block.logo}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    />
                                </Icon>
                            </Link>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: "none", md: "none", lg: "flex" },
                                alignItems: "center",
                                gap: "25px",
                            }}
                        >
                            <Link
                                component={NextLink}
                                href="/"
                                locale={
                                    router.locale === EN_LOCALE
                                        ? "fr-FR"
                                        : router.locale
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faHome}
                                    size="lg"
                                    color={colors.primary.main}
                                />
                            </Link>
                            {block.navigationMenu.map((item, i) => {
                                return item.href != null ? (
                                    <Link
                                        component={NextLink}
                                        style={{
                                            //Quick & dirty fix ...
                                            ...([
                                                "Vacatures",
                                                "Vacancies",
                                                "Jobs",
                                                "Postes vacants",
                                                "Offres d'emploi",
                                            ].includes(item.name)
                                                ? {
                                                      backgroundColor:
                                                          colors.green.main,
                                                      paddingTop: "8px",
                                                      paddingBottom: "8px",
                                                      paddingRight: "16px",
                                                      paddingLeft: "16px",
                                                      fontWeight: 400,
                                                  }
                                                : {}),
                                        }}
                                        href={item.href}
                                        locale={
                                            router.locale === EN_LOCALE &&
                                            !isITpage(item.href)
                                                ? "fr-FR"
                                                : router.locale
                                        }
                                        key={i}
                                    >
                                        {item.name}
                                    </Link>
                                ) : (
                                    <span key={i}>
                                        <Link
                                            sx={{
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                textTransform: "none",
                                            }}
                                            onClick={(e) =>
                                                handleMenu(e, item.name)
                                            }
                                        >
                                            {item.isChangeLocale
                                                ? getLocaleLabel(
                                                      item,
                                                      router.locale,
                                                  )
                                                : item.name}
                                            {Boolean(
                                                anchorEl && anchorEl[item.name],
                                            ) ? (
                                                <ExpandLessIcon
                                                    fontSize={"small"}
                                                />
                                            ) : (
                                                <ExpandMoreIcon
                                                    fontSize={"small"}
                                                />
                                            )}
                                        </Link>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={
                                                anchorEl && anchorEl[item.name]
                                            }
                                            keepMounted={false}
                                            open={Boolean(
                                                anchorEl && anchorEl[item.name],
                                            )}
                                            onClose={handleClose}
                                            PaperProps={{
                                                style: {
                                                    transform:
                                                        "translateX(8px) translateY(25px)",
                                                },
                                            }}
                                        >
                                            {item.links.map((child, index) => (
                                                <MenuItem
                                                    onClick={handleClose}
                                                    key={index + child.name}
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                >
                                                    <Link
                                                        component={NextLink}
                                                        style={{
                                                            width: "100%",
                                                            justifyContent:
                                                                "flex-start",
                                                            textAlign: "left",
                                                        }}
                                                        href={child.href ?? "/"}
                                                        locale={
                                                            router.locale ===
                                                            EN_LOCALE
                                                                ? "fr-FR"
                                                                : router.locale
                                                        }
                                                    >
                                                        {child.name}
                                                    </Link>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </span>
                                );
                            })}
                            <Link
                                component={Button}
                                disabled={!localizations.length}
                                sx={{
                                    textTransform: "uppercase",
                                }}
                                onClick={(e) => handleMenu(e, "langSwitcher")}
                            >
                                {router.locale
                                    ? router.locale === FR_LOCALE
                                        ? FR_LABEL
                                        : router.locale
                                    : "NL"}
                                {Boolean(
                                    anchorEl && anchorEl["langSwitcher"],
                                ) ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </Link>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl && anchorEl["langSwitcher"]}
                                keepMounted={false}
                                open={Boolean(
                                    anchorEl && anchorEl["langSwitcher"],
                                )}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        transform:
                                            "translateX(8px) translateY(25px)",
                                    },
                                }}
                            >
                                {localizations.map((child, index) => {
                                    return (
                                        <MenuItem
                                            onClick={handleClose}
                                            key={index + child.locale}
                                            sx={{
                                                width: "fit-content",
                                            }}
                                        >
                                            <Link
                                                component={Button}
                                                style={{
                                                    width: "100px",
                                                    textAlign: "center",
                                                    textTransform: "uppercase",
                                                }}
                                                onClick={() =>
                                                    onSelectOtherLanguage(
                                                        child.locale,
                                                    )
                                                }
                                            >
                                                {child.locale === FR_LOCALE
                                                    ? FR_LABEL
                                                    : child.locale}
                                            </Link>
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </Box>
                        <MobileNavBar
                            handleDrawerToggle={handleDrawerToggle}
                            mobileOpen={mobileOpen}
                            block={block}
                            localizations={localizations}
                            onSelectOtherLanguage={onSelectOtherLanguage}
                        />
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 0, display: { lg: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
};

export default NavigationBar;

interface MobileNavBarProps {
    onSelectOtherLanguage: (language: string) => void;
    localizations: APITypes.Localization[];
    block: APITypes.Components.ContentBlocks.NavigationBar;
    mobileOpen: boolean;
    handleDrawerToggle: () => void;
}

const MobileNavBar = ({
    localizations,
    block,
    mobileOpen,
    onSelectOtherLanguage,
    handleDrawerToggle,
}: MobileNavBarProps) => {
    const [open, setOpen] = React.useState<{ [key: string]: boolean }>({});
    const router = useRouter();
    const handleClick = (index: string) => {
        setOpen({ [index]: !open[index] });
    };

    return (
        <Box component="nav">
            <Drawer
                anchor={"right"}
                container={undefined}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { md: "flex", lg: "none" },
                    "& .MuiDrawer-paper": {
                        backgroundColor: "#00497C",
                        boxSizing: "border-box",
                        width: "100%",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        height: "100%",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            py: 2,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            px: "20px",
                        }}
                    >
                        <Link
                            component={NextLink}
                            href={`/`}
                            style={{
                                width: "121px",
                                height: "53px",
                            }}
                            locale={router.locale}
                            onClick={handleDrawerToggle}
                        >
                            <Icon
                                sx={{
                                    width: "121px",
                                    height: "53px",
                                }}
                            >
                                <Box
                                    component="img"
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                    alt="Acerta logo"
                                    src="/Acerta.svg"
                                />
                            </Icon>
                        </Link>
                        <IconButton
                            style={{
                                marginLeft: "auto",
                                color: "white",
                                alignItems: "flex-start",
                            }}
                            color="primary"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        component="nav"
                        sx={{
                            alignSelf: "center",
                            display: "flex",
                            height: "100%",
                        }}
                    >
                        <List
                            component="nav"
                            sx={{
                                height: "100%",
                            }}
                        >
                            {block.navigationMenu.map((item, index) =>
                                item.href != null ? (
                                    <ListItemButton
                                        sx={{
                                            mt: 1,
                                            textAlign: "left",
                                        }}
                                        key={index}
                                        onClick={handleDrawerToggle}
                                    >
                                        <Link
                                            component={NextLink}
                                            style={{
                                                ...drawerButtonStyle,
                                                ...([
                                                    "Vacatures",
                                                    "Vacancies",
                                                    "Jobs",
                                                    "Postes vacants",
                                                    "Offres d'emploi",
                                                ].includes(item.name)
                                                    ? {
                                                          position: "absolute",
                                                          top: 1,
                                                          left: 0,
                                                          width: "fit-content",
                                                          backgroundColor:
                                                              colors.green.main,
                                                          fontWeight: 400,
                                                          color: "white",
                                                          paddingTop: "8px",
                                                          paddingBottom: "8px",
                                                          paddingRight: "16px",
                                                          paddingLeft: "16px",
                                                          height: "fit-content",
                                                      }
                                                    : {}),
                                            }}
                                            href={item.href}
                                            locale={router.locale}
                                        >
                                            {item.name}
                                        </Link>
                                    </ListItemButton>
                                ) : (
                                    <Box key={index}>
                                        <ListItemButton
                                            onClick={() =>
                                                handleClick(item.name)
                                            }
                                            sx={{
                                                textAlign: "left",
                                            }}
                                        >
                                            <Link
                                                style={{
                                                    ...drawerButtonStyle,
                                                    display: "flex",
                                                    textTransform: "none",
                                                    justifyContent: "left",
                                                }}
                                            >
                                                {item.name}
                                                {open[item.name] ? (
                                                    <ExpandLess />
                                                ) : (
                                                    <ExpandMore />
                                                )}
                                            </Link>
                                        </ListItemButton>
                                        <Collapse
                                            sx={{
                                                textAlign: "left",
                                                paddingLeft: 3,
                                            }}
                                            in={open[item.name]}
                                            timeout="auto"
                                            unmountOnExit
                                            key={item.name}
                                        >
                                            <List
                                                component="div"
                                                disablePadding
                                            >
                                                {item.links.map((child, i) => (
                                                    <ListItemButton
                                                        sx={{
                                                            textAlign: "left",
                                                        }}
                                                        key={i}
                                                    >
                                                        <Link
                                                            component={NextLink}
                                                            style={
                                                                drawerButtonStyle
                                                            }
                                                            href={
                                                                child.href ?? ""
                                                            }
                                                            onClick={
                                                                handleDrawerToggle
                                                            }
                                                            locale={
                                                                router.locale
                                                            }
                                                        >
                                                            {child.name}
                                                        </Link>
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </Box>
                                ),
                            )}
                        </List>
                    </Box>
                    <Box
                        sx={{
                            alignSelf: "flex-end",
                        }}
                    >
                        <ListItemButton
                            onClick={() => handleClick("mobileSwitchLocale")}
                            sx={{ textAlign: "left" }}
                        >
                            <Link
                                component={Button}
                                style={{
                                    ...drawerButtonStyle,
                                    justifyContent: "left",
                                    textTransform: "uppercase",
                                }}
                                onClick={() =>
                                    handleClick("mobileSwitchLocale")
                                }
                            >
                                {router.locale
                                    ? router.locale === "fr-FR"
                                        ? "FR"
                                        : router.locale
                                    : "NL"}
                                {open["mobileSwitchLocale"] ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </Link>
                        </ListItemButton>
                        <Collapse
                            in={open["mobileSwitchLocale"]}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                {localizations.map((localization, i) => (
                                    <ListItemButton
                                        sx={{
                                            textAlign: "left",
                                            textTransform: "uppercase",
                                        }}
                                        key={i}
                                    >
                                        <Link
                                            component={Button}
                                            style={{
                                                ...drawerButtonStyle,
                                                justifyContent: "left",
                                            }}
                                            onClick={() =>
                                                onSelectOtherLanguage(
                                                    localization.locale,
                                                )
                                            }
                                        >
                                            {localization.locale === "fr-FR"
                                                ? "FR"
                                                : localization.locale}
                                        </Link>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};
