import * as Yup from "yup";

const digitsOnly = (value: string) => /^(?:[+] *)?[0-9][0-9 ]*$/.test(value);

export const validationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
    birthDate: Yup.string()
        .required("Birth date is required")
        .matches(
            /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
            "Invalid date format. Please use YYYY-MM-DD format",
        ),
    number: Yup.string()
        .required("Please enter valid number")
        .test("Digits only", "Digits only", digitsOnly)
        .min(7, "Please enter valid number"),
    // .max(15, "Maximum number limits"),
    url: Yup.string().url("URL is not valid"),
    cv: Yup.bool().oneOf([true], "CV is required").required("CV is required"),
    privacy: Yup.bool()
        .oneOf([true], "Select is required")
        .required("Select is required"),
}).required();
