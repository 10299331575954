import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Markdown } from "components";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";

export interface TextBlockProps {
    block: APITypes.Components.ContentBlocks.TextBlockComponent;
}

const TextBlock = ({ block }: TextBlockProps) => {
    const { title, description, hexTextColor, hexBackgroundColor } = block;

    return (
        <Box
            sx={{
                backgroundColor: hexBackgroundColor,
                color: hexTextColor,
            }}
            py={{ xs: 6, md: 14 }}
        >
            <Container maxWidth="lg">
                <Stack
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent={"center"}
                    alignItems={"start"}
                    gap={{ xs: 2, md: 6 }}
                >
                    <Stack
                        sx={{
                            flex: 1,
                            width: "100%",
                        }}
                    >
                        <Typography
                            component={"div"}
                            variant="h2"
                            sx={{
                                textAlign: { xs: "left", md: "right" },
                                "& p": {
                                    margin: "0",
                                },
                            }}
                        >
                            <SanitizeString content={title} />
                        </Typography>
                    </Stack>
                    <Stack
                        sx={{
                            flex: 1,
                            width: "100%",
                        }}
                    >
                        <Typography
                            component={"div"}
                            sx={{
                                "& p": {
                                    margin: "0",
                                },
                            }}
                        >
                            <Markdown content={description} />
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};
export default TextBlock;
