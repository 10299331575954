import { Markdown } from "@components/Markdown";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import LandingPageContactForm from "./LandingPageContactForm";
import { colors } from "@components/Theme/colors";

export interface LandingPageContactBlockProps {
    block: APITypes.Components.ContentBlocks.LandingPageContactFrom;
}

const LandingPageContactBlock = ({ block }: LandingPageContactBlockProps) => {
    const { title, description } = block;

    return (
        <Box
            id="form"
            sx={{
                backgroundColor: colors.greyColor.light,
            }}
            py={{ xs: 8, lg: 20 }}
        >
            <Container maxWidth="xl">
                <Stack gap={4}>
                    <Typography variant="h2">{title}</Typography>
                    <Typography
                        component={"div"}
                        sx={{
                            "& a": {
                                fontWeight: 600,
                                color: colors.secondary.main,
                            },
                        }}
                    >
                        <Markdown content={description} />
                    </Typography>
                    <Container maxWidth="md">
                        <LandingPageContactForm form={block} />
                    </Container>
                </Stack>
            </Container>
        </Box>
    );
};
export default LandingPageContactBlock;
