import VideoItem from "@components/blocks/VideoCarousel/VideoItem/VideoItem";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import { Markdown } from "@components/Markdown";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/core";
import { colors } from "@components/Theme/colors";
import { useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export interface IVideoCarousel {
    block: APITypes.Components.ContentBlocks.VideoCarousel;
}

export default function VideoCarousel({ block }: IVideoCarousel) {
    const { videos, titleText } = block;
    const mainRef = useRef<Splide>(null);
    const [playerPlayVideo, setPlayerPlayVideo] = useState<any>(() => {});
    const [isHideBox, setIsHideBox] = useState(false);

    const handleBack = () => {
        if (mainRef.current) {
            mainRef.current.go("<");
            playerPlayVideo?.pauseVideo();
        }
    };

    const handleNext = () => {
        if (mainRef.current) {
            mainRef.current.go(">");
            playerPlayVideo.pauseVideo();
        }
    };

    return (
        <Container
            maxWidth={"xl"}
            disableGutters
            sx={{
                "& .splide": {
                    width: "100%",
                    padding: 0,
                },
            }}
        >
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <Container
                    maxWidth={"xl"}
                    sx={{
                        position: { xs: "flex", md: "absolute" },
                        display: isHideBox ? "none" : "flex",
                        top: 0,
                        left: 0,
                        zIndex: 2,
                        width: "100%",
                        height: "100%",
                        px: { xs: 0, md: "24px" },
                        pointerEvents: "none",
                    }}
                >
                    <Stack
                        alignItems={"end"}
                        justifyContent={"end"}
                        height={"100%"}
                    >
                        <Stack
                            sx={{
                                padding: 4,
                                background: colors.secondary.main,
                                color: colors.white.main,
                                maxWidth: { xs: "100%", lg: "40%" },
                            }}
                        >
                            <Typography
                                variant="h2"
                                style={{
                                    fontSize: "2rem",
                                    color: colors.white.main,
                                }}
                            >
                                {titleText.title}
                            </Typography>
                            <Typography component={"div"} variant="body1">
                                <Markdown content={titleText.text} />
                            </Typography>
                            {videos.length === 1 ? (
                                <Stack></Stack>
                            ) : (
                                <Stack
                                    flexDirection={"row"}
                                    justifyContent={"end"}
                                    gap={2}
                                >
                                    <Button
                                        sx={{
                                            px: 1,
                                            py: 0.5,
                                            pointerEvents: "auto",
                                        }}
                                        color="green"
                                        variant="contained"
                                        onClick={handleBack}
                                    >
                                        <ArrowBackIcon fontSize="small" />
                                    </Button>
                                    <Button
                                        sx={{
                                            px: 1,
                                            py: 0.5,
                                            pointerEvents: "auto",
                                        }}
                                        color="green"
                                        onClick={handleNext}
                                        variant="contained"
                                    >
                                        <ArrowForwardIcon fontSize="small" />
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Container>
                <Splide
                    ref={mainRef}
                    options={{
                        type: "loop",
                        perPage: 1,
                        focus: "center",
                        arrows: false,
                        pagination: false,
                    }}
                >
                    {videos.map((item, i) => (
                        <SplideSlide key={i}>
                            <VideoItem
                                video={item.video}
                                setPlayerPlayVideo={setPlayerPlayVideo}
                                setIsHideBox={setIsHideBox}
                                key={i}
                            />
                        </SplideSlide>
                    ))}
                </Splide>
            </Box>
        </Container>
    );
}
