import { Markdown } from "@components/Markdown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";

export interface ListBlockProps {
    block: APITypes.Components.ContentBlocks.ListBlock;
}

const ListBlock = ({ block }: ListBlockProps) => {
    const { list } = block;

    return (
        <Box
            sx={{
                backgroundColor: "#F3F3F3",
                pt: { xs: "20px", lg: "40px" },
                pb: { xs: "60px", lg: "156px" },
            }}
        >
            <Container maxWidth="lg">
                <Typography
                    variant="h2"
                    component={"h2"}
                    sx={{
                        fontSize: {
                            xs: "40px",
                            lg: "54px",
                            fontWeight: 700,
                            color: "#093950",
                        },
                        mb: "65px",
                    }}
                >
                    {list.title}
                </Typography>
                <Grid
                    container
                    sx={{
                        paddingLeft: { xs: 0, lg: 14 },
                        justifyContent: { xs: "center", lg: "flex-start" },
                    }}
                >
                    <Grid item xs={10} lg={12}>
                        {list.listItem.map((item, i) => (
                            <Accordion
                                key={i}
                                defaultExpanded={true}
                                sx={{
                                    minHeight: "129px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    "&:before": {
                                        backgroundColor:
                                            "transparent !important",
                                    },
                                    boxShadow: "none",
                                    mb: "10px",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            fontSize="large"
                                            sx={{ color: "#093950" }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                    component={'h3'}
                                        sx={{
                                            color: "#093950",
                                            fontSize: {
                                                xs: "27px",
                                                lg: "36px",
                                            },
                                            fontWeight: "700",
                                            paddingLeft: 2,
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        component={"div"}
                                        sx={{
                                            fontSize: "18px",
                                            paddingLeft: { xs: 1.5, lg: 8 },
                                            color: "#093950",
                                            a: {
                                                color: "#093950",
                                            },
                                        }}
                                    >
                                        <Markdown content={item.subTitle} />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default ListBlock;
