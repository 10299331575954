import { Markdown } from "@components/Markdown";
import StopIcon from "@mui/icons-material/Stop";
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, Formik, useFormik } from "formik";
import { useState } from "react";
import { APITypes } from "utils/api.types";
import { submitForm } from "utils/fetchingFunctions";
import * as Yup from "yup";

export interface InternshipContactFormProps {
    block: APITypes.Components.ContentBlocks.InternshipForm;
}

const InternshipContactForm = ({ block }: InternshipContactFormProps) => {
    const { title, description, subTitle } = block;

    return (
        <Box
            id="form"
            sx={{
                backgroundColor: "#F3F3F3",
                color: "#202020",
            }}
            pb={20}
        >
            <Container maxWidth="md">
                <Grid
                    pt={20}
                    container
                    direction={"row"}
                    spacing={4}
                    justifyContent={"flex-start"}
                >
                    <Grid item xs={12} md={9}>
                        <Typography
                            sx={{
                                align: "left",
                                textAlign: "left",
                                fontSize: "24px",
                                fontWeight: {
                                    lg: 700,
                                    xs: 600,
                                },
                                lineHeight: "48px",
                            }}
                        >
                            {subTitle}
                        </Typography>
                        <Typography
                            sx={{
                                align: "left",
                                textAlign: "left",
                                fontSize: {
                                    lg: "36px",
                                    xs: "24px",
                                },
                                fontWeight: {
                                    lg: 700,
                                    xs: 600,
                                },
                                lineHeight: "48px",
                            }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            component={"div"}
                            sx={{
                                align: "left",
                                textAlign: "left",
                                fontSize: "16px",
                                fontWeight: 400,
                            }}
                        >
                            <Markdown content={description} />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <WithMaterialUI form={block} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default InternshipContactForm;

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
    school: Yup.string().required("School is required"),
    course: Yup.string().required("Course is required"),
    motivation: Yup.string().required("Motivation is required"),
    track: Yup.string().required("Track is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    privacy: Yup.bool()
        .oneOf([true], "Select is required")
        .required("Select is required"),
}).required();

const textFieldStyle = {
    border: 0,
    borderRadius: 0,
    "& fieldset": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    input: {
        backgroundColor: "white",
        "&::placeholder": {
            color: "#093950",
        },
    },

    "& .MuiInputBase-multiline": {
        backgroundColor: "white",
        "&::placeholder": {
            color: "#093950",
        },
    },
    "& .MuiSelect-select ": {
        backgroundColor: "white",
        "&::placeholder": {
            color: "#093950",
        },
    },
};

interface WithMaterialUIProps {
    form: APITypes.Components.ContentBlocks.InternshipForm;
}

const WithMaterialUI = ({ form }: WithMaterialUIProps) => {
    const {
        namePlaceholder,
        emailAddressPlaceholder,
        coursePlaceholder,
        startDatePlaceholder,
        schoolPlaceholder,
        selectOptions,
        selectPlaceholder,
        stageTitle,
        personalInfoTitle,
        submitButton,
        checkBox,
        motivationPlaceholder,
        endDatePlaceholder,
    } = form;

    const [isFormSent, setFormSent] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            school: "",
            course: "",
            startDate: "",
            endDate: "",
            track: "",
            motivation: "",
            privacy: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await submitForm("Internship form", values);
                if (res.status === 200) {
                    resetForm();
                    setFormSent(true);
                    (window as any).dataLayer.push({
                        event: "Internship form sent",
                    });
                } else {
                    console.error(res);
                }
            } catch (error) {
                console.error(error);
            }
        },
    });

    return (
        <div>
            {isFormSent ? (
                <Box mt={-2}>
                    <Typography
                        sx={{
                            align: "left",
                            textAlign: "left",
                            fontSize: {
                                lg: "36px",
                                xs: "24px",
                            },
                            fontWeight: {
                                lg: 700,
                                xs: 600,
                            },
                            lineHeight: "48px",
                        }}
                    >
                        {submitButton.successMessage}
                    </Typography>
                    <Typography
                        sx={{
                            align: "left",
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: 400,
                        }}
                    >
                        {submitButton.successDescription}
                    </Typography>
                </Box>
            ) : (
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        school: "",
                        course: "",
                        track: "",
                    }}
                    onSubmit={() => { }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container direction={"row"} spacing={1}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        align: "left",
                                        textAlign: "left",
                                        fontSize: "24px",
                                        fontWeight: {
                                            lg: 700,
                                            xs: 600,
                                        },
                                        lineHeight: "48px",
                                    }}
                                >
                                    {personalInfoTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    placeholder={namePlaceholder}
                                    sx={textFieldStyle}
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={textFieldStyle}
                                    fullWidth
                                    id="email"
                                    name="email"
                                    placeholder={emailAddressPlaceholder}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={textFieldStyle}
                                    fullWidth
                                    id="school"
                                    name="school"
                                    placeholder={schoolPlaceholder}
                                    value={formik.values.school}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.school &&
                                        Boolean(formik.errors.school)
                                    }
                                    helperText={
                                        formik.touched.school &&
                                        formik.errors.school
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    sx={textFieldStyle}
                                    fullWidth
                                    id="course"
                                    name="course"
                                    placeholder={coursePlaceholder}
                                    value={formik.values.course}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.course &&
                                        Boolean(formik.errors.course)
                                    }
                                    helperText={
                                        formik.touched.course &&
                                        formik.errors.course
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} mt={2} mb={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        align: "left",
                                        textAlign: "left",
                                        fontSize: "24px",
                                        fontWeight: {
                                            lg: 700,
                                            xs: 600,
                                        },
                                        lineHeight: "48px",
                                    }}
                                >
                                    {stageTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            name: "startDate",
                                            helperText:
                                                formik.touched.startDate &&
                                                formik.errors.startDate,
                                            error:
                                                formik.touched.startDate &&
                                                Boolean(
                                                    formik.errors.startDate,
                                                ),
                                            sx: {
                                                input: {
                                                    backgroundColor: "white",
                                                    "&::placeholder": {
                                                        color: "#093950",
                                                    },
                                                },
                                                "& .MuiFormHelperText-root": {
                                                    backgroundColor:
                                                        "transparent",
                                                },
                                                "& .MuiInputBase-adornedEnd": {
                                                    backgroundColor: "white",
                                                },
                                            },
                                        },
                                    }}
                                    disablePast={true}
                                    label={startDatePlaceholder}
                                    onChange={(val) => {
                                        formik.setFieldValue("startDate", val);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    minDate={formik.values.startDate}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            name: "endDate",
                                            helperText:
                                                formik.touched.endDate &&
                                                formik.errors.endDate,
                                            error:
                                                formik.touched.endDate &&
                                                Boolean(formik.errors.endDate),
                                            sx: {
                                                input: {
                                                    backgroundColor: "white",
                                                    "&::placeholder": {
                                                        color: "#093950",
                                                    },
                                                },
                                                "& .MuiFormHelperText-root": {
                                                    backgroundColor:
                                                        "transparent",
                                                },
                                                "& .MuiInputBase-adornedEnd": {
                                                    backgroundColor: "white",
                                                },
                                            },
                                        },
                                    }}
                                    disablePast={true}
                                    label={endDatePlaceholder}
                                    onChange={(val) => {
                                        formik.setFieldValue("endDate", val);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Select
                                    sx={{
                                        ...textFieldStyle,
                                        "& .MuiSelect-select .notranslate::after":
                                            selectPlaceholder
                                                ? {
                                                    content: `"${selectPlaceholder}"`,
                                                    opacity: 0.42,
                                                }
                                                : {},
                                    }}
                                    fullWidth
                                    name="track"
                                    id="track"
                                    value={formik.values.track}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.track &&
                                        Boolean(formik.errors.track)
                                    }
                                >
                                    {selectOptions.map((option, i) => (
                                        <MenuItem key={i} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText
                                    sx={{ color: "red", ml: "14px" }}
                                >
                                    {formik.touched.track &&
                                        formik.errors.track}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={textFieldStyle}
                                    multiline={true}
                                    rows={4}
                                    fullWidth
                                    id="motivation"
                                    name="motivation"
                                    placeholder={motivationPlaceholder}
                                    value={formik.values.motivation}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.motivation &&
                                        Boolean(formik.errors.motivation)
                                    }
                                    helperText={
                                        formik.touched.motivation &&
                                        formik.errors.motivation
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} mt={2} mb={2}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction={"row"}>
                                    <Grid item xs={4} md={2}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                fontSize: {
                                                    xs: "16px",
                                                    lg: "18px",
                                                },
                                                fontWeight: 700,
                                                textTransform: "none",
                                                "&.MuiButton-contained": {
                                                    backgroundColor:
                                                        form.submitButton
                                                            .hexColor,
                                                },
                                                borderRadius: 0,
                                            }}
                                        >
                                            {form.submitButton.title}
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6} md={10}>
                                        {checkBox.map((checkbox, i) => (
                                            <Grid item xs={12} key={i}>
                                                <Field
                                                    sx={{
                                                        "& a": {
                                                            color: "#3EA4DC",
                                                            textDecoration:
                                                                "none",
                                                        },
                                                        "& .MuiFormControlLabel-label":
                                                        {
                                                            fontSize: {
                                                                xs: "14px",
                                                                lg: "16px",
                                                            },
                                                        },
                                                    }}
                                                    type="checkbox"
                                                    name={"privacy"}
                                                    as={FormControlLabel}
                                                    control={
                                                        <Checkbox
                                                            sx={{
                                                                border: 0,
                                                                color: "white",
                                                            }}
                                                            checkedIcon={
                                                                <StopIcon
                                                                    sx={{
                                                                        fontSize:
                                                                            "24px",
                                                                        border: 0.2,
                                                                        borderRadius: 0.5,
                                                                        color: form
                                                                            .submitButton
                                                                            .hexColor,
                                                                        backgroundColor:
                                                                            "white",
                                                                        borderColor:
                                                                            "white",
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    }
                                                    checked={
                                                        formik.values.privacy
                                                    }
                                                    label={
                                                        checkbox.label ? (
                                                            <Markdown
                                                                content={
                                                                    checkbox.label
                                                                }
                                                            />
                                                        ) : (
                                                            checkbox.Name
                                                        )
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                                <FormHelperText
                                                    sx={{
                                                        color: "red",
                                                        ml: "14px",
                                                    }}
                                                >
                                                    {formik.touched.privacy &&
                                                        formik.errors.privacy}
                                                </FormHelperText>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Formik>
            )}
        </div>
    );
};
