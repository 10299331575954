import { Box } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

export function BreadCrumbs() {
    const { asPath, isReady } = useRouter();

    const allSubroutes: string[] = useMemo(() => {
        if (isReady) {
            const startOfParams = asPath.indexOf("?");
            let path = asPath;
            if (startOfParams !== -1) {
                path = asPath.substring(0, startOfParams);
            }
            const routeParts = path.split("/");
            const slug = routeParts[routeParts.length - 1];
            return routeParts.length === 0
                ? [slug]
                : routeParts
                      .slice(1, routeParts.length)
                      .filter((str) => str !== "");
        }
        return [];
    }, [asPath, isReady]);

    const allRoutes = useMemo(
        () =>
            ["Acerta", ...allSubroutes]
                .map((r) => r[0].toUpperCase() + r.substring(1, r.length))
                .map((r) => r.replaceAll("-", " "))
                .map((r) => r.split("#")[0]),
        [allSubroutes],
    );

    const getLink = useCallback(
        (index: number) => {
            const link = allRoutes
                .slice(1, index + 1)
                .map((r) => r.toLowerCase())
                .map((r) => r.replaceAll(" ", "-"))
                .join("/");
            return "/" + link;
        },
        [allRoutes],
    );

    if (allRoutes.length > 1) {
        return (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                {allRoutes.map((route, index) => {
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                            key={route}
                        >
                            <Box sx={{ display: "flex" }}>
                                <Link
                                    style={{
                                        color:
                                            index === allRoutes.length - 1
                                                ? "#E6539B"
                                                : "#6F6E6E",
                                        textDecoration: "none",
                                        fontWeight: "520",
                                        fontSize: "16px",
                                    }}
                                    href={getLink(index)}
                                >
                                    {route}
                                </Link>
                            </Box>
                            {index < allRoutes.length - 1 && (
                                <Box
                                    sx={{
                                        px: "8px",
                                        color: "#54B8E3",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                    }}
                                >
                                    /
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
        );
    }

    return <>{allSubroutes.map((s) => s.replace("-", " ")).join(" > ")}</>;
}
