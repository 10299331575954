import LandingPageHero from "@components/blocks/LandingPageHero/LandingPageHero";
import { APITypes } from "utils/api.types";

export interface HeroProps {
    block: APITypes.Components.ContentBlocks.Hero;
}

const Hero = ({ block }: HeroProps) => {
    const { backgroundImage, title, titleAlignment, backgroundColor } = block;
    return (
        <LandingPageHero
            block={
                {
                    ...block,
                    heroImage: backgroundImage,
                    heroSquareBlock: {
                        title: title,
                    },
                } as any
            }
        />
        // <Box
        //     pb={6}
        //     sx={{
        //         backgroundColor: backgroundColor,
        //     }}
        // >
        //     <Container maxWidth="lg">
        //         <Box style={{ position: "relative" }}>
        //             <Box
        //                 style={{
        //                     position: "relative",
        //                     width: "100vw",
        //                     left: "calc(-50vw + 50%)",
        //                 }}
        //             >
        //                 <Image
        //                     image={backgroundImage}
        //                     alt={backgroundImage?.name}
        //                     layout="responsive"
        //                 />
        //             </Box>
        //             <Typography
        //                 sx={{
        //                     [titleAlignment]: {
        //                         lg: "calc(10% - 100px)",
        //                         sm: "calc(10% - 64px)",
        //                         xs: "calc(10% - 42px)",
        //                     },
        //                     top: {
        //                         lg: "calc(80% - 190px)",
        //                         sm: "calc(80% - 150px)",
        //                         xs: "calc(80% - 80px)",
        //                     },
        //                     position: "absolute",
        //                     textAlign: titleAlignment,
        //                     color: "white",
        //                     fontSize: {
        //                         lg: "100px",
        //                         sm: "64px",
        //                         xs: "30px",
        //                     },
        //                     fontWeight: {
        //                         lg: 700,
        //                         xs: 600,
        //                     },
        //                 }}
        //             >
        //                 {title}
        //             </Typography>
        //         </Box>
        //     </Container>
        // </Box>
    );
};

export { Hero };
