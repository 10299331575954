export const LocationsMappings = new Map<string, string[]>([
    ["aalst", ["aalst"]],
    ["antwerpen-centrum", ["antwerpen-centrum", "antwerpen", "centrum"]],
    ["antwerpen-wilrijk", ["antwerpen-wilrijk", "wilrijk", "antwerpen"]],
    ["liège", ["liège", "liege", "luik"]],
    ["brugge", ["brugge", "bruges"]],
    ["brussel", ["brussel", "bruxelles", "brussels"]],
    [
        "brussel-louizalaan",
        ["brussel-louizalaan", "brussel", "brussels", "bruxelles"],
    ],
    ["charleroi", ["charleroi"]],
    ["dendermonde", ["dendermonde"]],
    ["dilbeek", ["dilbeek"]],
    ["gent", ["gent", "ghent"]],
    ["hasselt", ["hasselt"]],
    ["jambes", ["jambes"]],
    ["kortrijk", ["kortrijk"]],
    ["leuven", ["leuven", "louvain"]],
    ["libramont", ["libramont"]],
    ["lier", ["lier"]],
    ["mechelen", ["mechelen", "malines"]],
    ["louvain-la-neuve", ["louvain-la-neuve",]],
    ["oostende", ["oostende"]],
    ["oudenaarde", ["oudenaarde"]],
    ["roeselare", ["roeselare"]],
    ["sint-niklaas", ["sint-niklaas", "niklaas"]],
    ["turnhout", ["turnhout"]],
    ["zaventem", ["zaventem"]],
    [
        "vlaanderen",
        [
            "aalst",
            "antwerpen-centrum",
            "antwerpen",
            "centrum",
            "wilrijk",
            "antwerpen-wilrijk",
            "brugge",
            "brussel",
            "brugge",
            "bruges",
            "dendermonde",
            "dilbeek",
            "gent",
            "ghent",
            "leuven",
            "louvain",
            "hasselt",
            "kortrijk",
            "lier",
            "mechelen",
            "malines",
            "oostende",
            "oudenaarde",
            "roeselare",
            "sint-niklaas",
            "niklaas",
            "turnhout",
            "zaventem",
        ],
    ], [
        "wallonie",
        [
            "liège", "liege", "luik",
            "charleroi",
            "jambes",
            "libramont",
            "louvain-la-neuve"
        ],
    ],
]);
