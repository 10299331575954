import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Markdown } from "components";
import { APITypes } from "utils/api.types";
import IconBlockAdaptable from "./IconBlockAdaptable";

export interface InfoIconBlocksProps {
    block: APITypes.Components.ContentBlocks.InfoIconBlocks;
}

const InfoIconBlocks = ({ block }: InfoIconBlocksProps) => {
    const { direction, textBlock, iconBlock, alignment } = block;

    return (
        <Box py={14}>
            <Box>
                <Container maxWidth="xl">
                    <Stack
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        width={"100%"}
                        gap={{ xs: 4, lg: 8 }}
                    >
                        <Stack maxWidth={{ xs: "100%", lg: "60%" }}>
                            <Typography
                                component={"div"}
                                align="center"
                                variant="h2"
                                sx={{
                                    "& p": {
                                        margin: "0",
                                    },
                                }}
                            >
                                <Markdown content={textBlock.title} />
                            </Typography>
                            <Typography
                                component={"div"}
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                <Markdown content={textBlock.description} />
                            </Typography>
                        </Stack>
                        <Stack
                            sx={{
                                justifyContent: "center",
                                alignItems: {
                                    xs: "center",
                                    lg: "start",
                                },
                                display: "flex",
                                flexDirection: {
                                    lg: "row",
                                    xs: "column",
                                },
                                flexWrap: "wrap",
                            }}
                        >
                            {iconBlock.map((block, i) => (
                                <IconBlockAdaptable
                                    key={i}
                                    alignment={alignment}
                                    direction={direction}
                                    amountOfBlocks={iconBlock.length}
                                    description={block.description}
                                    image={block.image}
                                    title={block.title}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};
export default InfoIconBlocks;
