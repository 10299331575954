import React from "react";
import { Chip, Box } from "@mui/material";
import { colors } from "@components/Theme/colors";
import ClearIcon from "@mui/icons-material/Clear";

interface SelectedChipsProps {
    selectedClusters: string[];
    selectedLocations: string[];
    selectedTypes: string[];
    selectedCategories: string[];
    handleRemoveChip: (value: string, category: string) => void;
}

const SelectedChips = ({
    selectedClusters,
    selectedLocations,
    selectedTypes,
    selectedCategories,
    handleRemoveChip,
}: SelectedChipsProps) => {
    const renderChips = (selectedOptions: string[], category: string) =>
        selectedOptions.map((option) => (
            <Chip
                key={option}
                label={
                    option.charAt(0).toUpperCase() +
                    option
                        .slice(1)
                        .replace(/-/g, " ")
                        .replace(/cluster/g, " ")
                }
                onDelete={() => handleRemoveChip(option, category)}
                sx={{
                    py: 3,
                    px: 2,
                    borderRadius: 0,
                    backgroundColor: colors.secondary.main,
                    color: colors.white.main,
                    "& .MuiChip-deleteIcon": {
                        color: colors.white.main,
                    },
                }}
                deleteIcon={<ClearIcon />}
            />
        ));

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {renderChips(selectedClusters, "cluster")}
            {renderChips(selectedLocations, "location")}
            {renderChips(selectedTypes, "type")}
            {renderChips(selectedCategories, "categories")}
        </Box>
    );
};

export default SelectedChips;
