import {
    Box,
    Button,
    Container,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { APITypes } from "utils/api.types";
import QuoteWithPicture from "./QuoteWithPicture";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "@components/Theme/colors";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/core";
import { useRef } from "react";
import theme from "@components/Theme/theme";

export interface IQuotesCarousel {
    block: APITypes.Components.ContentBlocks.QuotesCarousel;
}

export default function QuotesCarousel({ block }: IQuotesCarousel) {
    const mainRef = useRef<Splide>(null);
    const isExtraSmallSize = useMediaQuery(theme.breakpoints.down("md"));

    const handleBack = () => {
        if (mainRef.current) {
            mainRef.current.go("<");
        }
    };

    const handleNext = () => {
        if (mainRef.current) {
            mainRef.current.go(">");
        }
    };

    const slidesPerPage = isExtraSmallSize ? 1 : 3;

    return (
        <Box sx={{ backgroundColor: colors.greyColor.main, py: 14 }}>
            <Container
                maxWidth="xl"
                sx={{
                    "& .splide": {
                        padding: 0,
                    },
                    "& .splide__slide": {
                        opacity: 0.5,
                        // transition: "transform .1s",
                    },
                    "& .splide__slide.is-visible.is-active": {
                        opacity: 1,
                        // transform: " scale(1.1)",
                    },
                }}
            >
                <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            maxWidth: { xs: "100%", md: "40%" },
                        }}
                    >
                        {block?.title}
                    </Typography>
                    <Stack
                        flexDirection={"row"}
                        gap={2}
                        sx={{ height: "fit-content" }}
                    >
                        <Button
                            sx={{ px: 1, py: 0.5 }}
                            variant="contained"
                            onClick={handleBack}
                        >
                            <ArrowBackIcon
                                sx={{ p: 0, m: 0, width: "20px" }}
                                fontSize="small"
                            />
                        </Button>
                        <Button
                            sx={{ px: 1, py: 0.5 }}
                            onClick={handleNext}
                            variant="contained"
                        >
                            <ArrowForwardIcon
                                sx={{ p: 0, m: 0, width: "20px" }}
                                fontSize="small"
                            />
                        </Button>
                    </Stack>
                </Stack>
                <Splide
                    ref={mainRef}
                    style={{
                        width: "100%",
                        display: "flex",
                    }}
                    options={{
                        type: "loop",
                        perPage: slidesPerPage,
                        focus: "center",
                        arrows: false,
                        pagination: false,
                    }}
                >
                    {block.quotes?.map((quote, i) => (
                        <SplideSlide
                            key={i}
                            style={{
                                display: "flex",
                            }}
                        >
                            <QuoteWithPicture block={quote} />
                        </SplideSlide>
                    ))}
                </Splide>
            </Container>
        </Box>
    );
}
