import { colors } from "@components/Theme/colors";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import "mapbox-gl/dist/mapbox-gl.css";
import NextLink from "next/link";
import { Ref, useCallback, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Map, { MapRef, Marker, NavigationControl, Popup } from "react-map-gl";
import Carousel from "react-material-ui-carousel";
import { APITypes } from "utils/api.types";
import { getBaseUrl } from "utils/fetchingFunctions";

import { Markdown } from "..";

import "mapbox-gl/dist/mapbox-gl.css";

export interface LocationsProps {
    block: APITypes.Components.ContentBlocks.Locations;
}

function Fallback({ error, resetErrorBoundary }: any) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
}

export default function Locations({ block }: LocationsProps) {
    const mapRef = useRef<MapRef>();
    const [popupOpen, setPopupOpen] = useState<any>({});
    const [ctrlPressed, setCtrlPressed] = useState(false);
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.down("md"));

    const offsetTop = 0;
    const offsetBottom = -30;
    const offsetLeft = 40;

    const markerOffsetObject = {
        top: [0, offsetTop],
        bottom: [0, offsetBottom],
        left: [offsetLeft, offsetBottom],
        right: [-offsetLeft, offsetBottom],
    };

    useEffect(() => {
        function handleKeyDown(event: KeyboardEvent) {
            if (event.key === "Control" || event.key === "Meta") {
                setCtrlPressed(true);
            }
        }

        function handleKeyUp(event: KeyboardEvent) {
            if (event.key === "Control" || event.key === "Meta") {
                setCtrlPressed(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    const onSelectCity = useCallback(
        ({ longitude, latitude }: APITypes.Components.Location) => {
            mapRef.current?.flyTo({
                center: [longitude, latitude],
                duration: 1000,
            });
        },
        [],
    );

    return (
        <>
            {!isMedium && (
                <Box
                    sx={{
                        backgroundColor: "#F3F3F3",

                        "& .mapboxgl-popup-close-button": {
                            backgroundColor: colors.secondary.main,
                            color: colors.white.main,
                            fontSize: "24px",
                            px: 1,
                            " &:hover": {
                                color: colors.white.main,
                                backgroundColor: colors.secondary.main,
                            },
                        },

                        "& .mapboxgl-popup-content": {
                            backgroundColor: "#F8D14C",
                            padding: 0,
                        },

                        "& .mapboxgl-popup-anchor-right .mapboxgl-popup-tip ": {
                            borderLeftColor: "#F8D14C",
                        },
                    }}
                >
                    <ErrorBoundary
                        FallbackComponent={Fallback}
                        onError={(error) =>
                            console.error(
                                "An error was caught while rendering the map: ",
                                error,
                            )
                        }
                    >
                        <Map
                            ref={mapRef as Ref<MapRef>}
                            scrollZoom={ctrlPressed}
                            style={{
                                width: "100%",
                                height: "700px",
                            }}
                            initialViewState={{
                                longitude: 4.469938,
                                latitude: 50.503887,
                                zoom: 7.4,
                            }}
                            mapStyle="mapbox://styles/mapbox/light-v11"
                            mapboxAccessToken={
                                process.env.NEXT_PUBLIC_REACT_APP_MAPBOX_TOKEN
                            }
                        >
                            <NavigationControl
                                showZoom={true}
                                showCompass={false}
                            />
                            {(block?.locations || []).map((location, i) => {
                                return (
                                    <Box key={i}>
                                        <Marker
                                            longitude={location.longitude}
                                            latitude={location.latitude}
                                            anchor="bottom"
                                        >
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPopupOpen({
                                                        [location.id]: true,
                                                    });
                                                    onSelectCity(location);
                                                }}
                                            >
                                                <svg
                                                    width="42"
                                                    height="51"
                                                    viewBox="0 0 42 51"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M20.9686 25.6117C22.3588 25.6117 23.549 25.1167 24.539 24.1266C25.529 23.1366 26.024 21.9465 26.024 20.5563C26.024 19.1661 25.529 17.976 24.539 16.986C23.549 15.996 22.3588 15.501 20.9686 15.501C19.5784 15.501 18.3883 15.996 17.3983 16.986C16.4083 17.976 15.9133 19.1661 15.9133 20.5563C15.9133 21.9465 16.4083 23.1366 17.3983 24.1266C18.3883 25.1167 19.5784 25.6117 20.9686 25.6117ZM20.9686 50.8884C14.186 45.1168 9.12017 39.7561 5.77101 34.8061C2.42185 29.856 0.747269 25.2746 0.747269 21.0618C0.747269 14.7427 2.77994 9.7084 6.84527 5.95903C10.9106 2.20965 15.6184 0.334961 20.9686 0.334961C26.3189 0.334961 31.0266 2.20965 35.092 5.95903C39.1573 9.7084 41.19 14.7427 41.19 21.0618C41.19 25.2746 39.5154 29.856 36.1662 34.8061C32.8171 39.7561 27.7512 45.1168 20.9686 50.8884Z"
                                                        fill="#026CFF"
                                                    />
                                                </svg>
                                            </div>
                                        </Marker>
                                        {popupOpen[location.id] && (
                                            <Popup
                                                style={{ height: "auto" }}
                                                anchor="right"
                                                maxWidth="fit-content"
                                                longitude={location.longitude}
                                                latitude={location.latitude}
                                                onClose={() =>
                                                    setPopupOpen({
                                                        ...popupOpen,
                                                        [location.id]: false,
                                                    })
                                                }
                                                closeButton={true}
                                                offset={
                                                    markerOffsetObject as any
                                                }
                                            >
                                                <LocationCard
                                                    location={location}
                                                    isRichText={true}
                                                />
                                            </Popup>
                                        )}
                                    </Box>
                                );
                            })}
                        </Map>
                    </ErrorBoundary>
                </Box>
            )}
            {isMedium && <LocationsCarousel block={block} />}
        </>
    );
}
export interface LocationsCarouselProps {
    block: APITypes.Components.ContentBlocks.Locations;
}

const LocationsCarousel = ({ block }: LocationsCarouselProps) => {
    return (
        <Box
            sx={{
                py: 10,
                backgroundColor: "#FFFFFF",
            }}
        >
            <Container maxWidth="lg">
                <Grid container justifyContent={"center"}>
                    <Carousel
                        indicators={true}
                        swipe={true}
                        autoPlay={false}
                        sx={{
                            width: "455px",
                        }}
                        navButtonsAlwaysInvisible={true}
                        navButtonsAlwaysVisible={false}
                        indicatorIconButtonProps={{
                            style: {
                                color: "lightGrey",
                                padding: "1px",
                                marginTop: "40px",
                                width: 1,
                            },
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                color: "black",
                                zIndex: 999,
                            },
                        }}
                        navButtonsProps={{
                            style: {
                                borderRadius: "50%",
                                backgroundColor: "white",
                                opacity: "40%",
                                color: "black",
                            },
                        }}
                    >
                        {block.locations.map((location, i) => (
                            <LocationCard
                                location={location}
                                key={i}
                                isRichText={false}
                            />
                        ))}
                    </Carousel>
                </Grid>
            </Container>
        </Box>
    );
};

interface LocationCardProps {
    location: APITypes.Components.Location;
    isRichText: boolean;
}

const LocationCard = ({ location, isRichText }: LocationCardProps) => {
    return (
        <Card
            sx={{
                maxHeight: "900px",
                height: "fit-content",
                backgroundColor: "#FAFAFA",
                boxShadow: "none",
                borderRadius: 0,
                width: "100%",
                pb: 4,
            }}
        >
            <Box
                pb={2}
                px={2}
                sx={{
                    width: "100%",
                    minHeight: "250px",
                    height: "auto",
                    paddingTop: {
                        xs: 0,
                        md: 30,
                    },
                    backgroundColor: "black",
                    backgroundImage: {
                        xs: `url(${getBaseUrl().base}${location.image.url})`,
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: {
                        xs: "flex-end",
                        md: "flex-end",
                    },
                    flex: 1,
                }}
            >
                <Typography
                    gutterBottom
                    variant="h5"
                    style={{ color: "white" }}
                >
                    {location.subTitle}
                </Typography>
                <Typography
                    gutterBottom
                    variant="h3"
                    style={{ color: "white" }}
                >
                    {location.title}
                </Typography>
            </Box>
            <CardContent
                sx={{
                    height: "fit-content",
                    padding: 2,
                    maxWidth: "455px",
                }}
            >
                <Typography
                    component={"div"}
                    sx={{
                        fontSize: "16px",
                        fontWeight: 500,
                    }}
                >
                    {isRichText ? (
                        <Markdown content={location.description} />
                    ) : (
                        location.description
                    )}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                }}
            >
                <Button
                    component={NextLink}
                    size="small"
                    variant="contained"
                    href={location.button.href}
                >
                    {location.button.title}
                </Button>
            </CardActions>
        </Card>
    );
};
