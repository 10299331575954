import { Link, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { APITypes } from "utils/api.types";
import SubFooter from "../SubFooter/SubFooter";
import { colors } from "@components/Theme/colors";
import NextLink from "next/link";
import { setNextLocaleCookieTo } from "utils/localization";
import { useCallback } from "react";
import { FR_LABEL, FR_LOCALE } from "@components/NavigationBar";
import { useRouter } from "next/router";

interface FooterProps {
    footer?: APITypes.Components.ContentBlocks.Footer;
    subFooter?: APITypes.Components.ContentBlocks.SubFooter;
    isMovingBarPresent: boolean;
    localizations: APITypes.Localization[];
    slug: string;
}

const Footer = ({
    footer,
    subFooter,
    isMovingBarPresent,
    localizations,
    slug,
}: FooterProps) => {
    const router = useRouter();

    const onSelectOtherLanguage = useCallback(
        (language: string) => {
            let lang = language;
            if (language === FR_LABEL) lang = FR_LOCALE;
            const updatedSlug = (
                localizations.find(
                    (localization) => localization.locale === lang,
                )?.slug ?? slug
            )
                .split("~")
                .join("/");
            const updatedPath =
                updatedSlug != null
                    ? `/${lang}/${updatedSlug !== "index" ? updatedSlug : ""}`
                    : lang;
            setNextLocaleCookieTo(lang);
            router.push(updatedPath, updatedPath, {
                locale: lang ?? undefined,
            });
        },
        [localizations, router, slug],
    );

    return (
        <Paper
            sx={{
                width: "100%",
                position: "relative",
                bottom: 0,
                boxShadow: 0,
                borderRadius: 0,
                paddingBottom: isMovingBarPresent ? "135px" : undefined,
            }}
            component="footer"
            square
            variant="elevation"
        >
            {subFooter && <SubFooter subFooter={subFooter} />}
            <Box
                sx={{
                    paddingY: "20px",
                    backgroundColor: colors.greyColor.main,
                }}
            >
                <Container
                    maxWidth="xl"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    {footer &&
                        footer.navigationMenu.map((nav, i) => (
                            <Stack
                                flexDirection={{
                                    xs: "column",
                                    md: "row",
                                }}
                                justifyContent={"space-between"}
                                key={i}
                            >
                                {nav.links.length > 0 && (
                                    <Stack
                                        flexDirection={{
                                            xs: "column",
                                            md: "row",
                                        }}
                                        gap={2}
                                    >
                                        {nav.links.map((item, i) => (
                                            <Link
                                                component={NextLink}
                                                key={i}
                                                href={item.href ?? "/"}
                                                underline="none"
                                                sx={{
                                                    fontSize: "0.875rem",
                                                    color: colors.primary.main,
                                                }}
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        ))}
                    <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
                        <Link
                            style={{
                                fontSize: "0.875rem",
                                color: colors.primary.main,
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                onSelectOtherLanguage(router.locale ?? "nl")
                            }
                        >
                            {router.locale
                                ? router.locale === FR_LOCALE
                                    ? FR_LABEL
                                    : router.locale.toUpperCase()
                                : "NL"}
                        </Link>
                        {localizations.map((child, index) => {
                            return (
                                <Link
                                    key={index}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "0.875rem",
                                        color: colors.primary.main,
                                    }}
                                    onClick={() =>
                                        onSelectOtherLanguage(child.locale)
                                    }
                                >
                                    {child.locale === FR_LOCALE
                                        ? FR_LABEL
                                        : child.locale.toUpperCase()}
                                </Link>
                            );
                        })}
                    </Stack>
                </Container>
            </Box>
        </Paper>
    );
};

export default Footer;
