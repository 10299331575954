import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import TitleBig from "@components/typography/TitleBig";
import { Box, CardContent, Container, Stack, Typography } from "@mui/material";
import { Markdown } from "components";
import { APITypes } from "utils/api.types";

export interface IColoredTextBlockProps {
    block: APITypes.Components.ContentBlocks.ColoredTextBlock;
}

const getPosition = (textBlockPosition: "left" | "center" | "right") => {
    switch (textBlockPosition) {
        case "left":
            return "start";
        case "center":
            return "center";
        case "right":
            return "end";
    }
};

const ColoredTextBlock = ({ block }: IColoredTextBlockProps) => {
    const {
        title,
        backgroundColor,
        textBlockPosition,
        content,
        textColor = "#000000",
        subTitle,
    } = block;

    const isCenter = textBlockPosition === "center";

    return (
        <Box
            sx={{
                py: "48px",
                backgroundColor: backgroundColor,
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Container
                    maxWidth="xl"
                    sx={{
                        display: "flex",
                        justifyContent: {
                            lg: getPosition(textBlockPosition),
                            sm: getPosition(textBlockPosition),
                            xs: "center",
                        },
                        marginBottom: "16px",
                    }}
                >
                    <Stack
                        sx={{
                            width: {
                                xs: "100%",
                                lg: isCenter ? "80%" : "60%",
                            },
                            background: backgroundColor,
                        }}
                    >
                        <Box
                            sx={{
                                margin: {
                                    lg: 6,
                                    xs: 2,
                                },
                            }}
                        >
                            <CardContent>
                                <Stack
                                    flexDirection={{
                                        xs: "column",
                                        md: isCenter ? "row" : "column",
                                    }}
                                    gap={{ xs: 2, md: isCenter ? 4 : 2 }}
                                >
                                    <Stack
                                        alignItems={{ xs: "start", md: "end" }}
                                        gap={4}
                                    >
                                        <Typography
                                            variant="h2"
                                            sx={{
                                                fontSize: {
                                                    xs: "1.5rem",
                                                    md: "2.188rem",
                                                },
                                                px: 2,
                                                mt: -4,
                                                backgroundColor:
                                                    colors.green.main,
                                            }}
                                        >
                                            {subTitle}
                                        </Typography>
                                        <TitleBig
                                            text={title}
                                            style={{
                                                color: textColor,
                                            }}
                                            sx={{
                                                "& p": {
                                                    m: 0,
                                                },
                                                textAlign: {
                                                    xs: "left",
                                                    lg: isCenter
                                                        ? "right"
                                                        : "left",
                                                },
                                            }}
                                        />
                                    </Stack>
                                    <Stack>
                                        {content && (
                                            <RegularText
                                                sx={{
                                                    mt: {
                                                        xs: 0,
                                                        md: isCenter ? 12 : 0,
                                                    },
                                                    color: textColor,
                                                    "& p": {
                                                        m: 0,
                                                    },
                                                    "& a": {
                                                        color: colors.primary
                                                            .main,
                                                        textDecoration: "none",
                                                        backgroundColor:
                                                            isCenter
                                                                ? "#E0E0E0"
                                                                : colors.green
                                                                      .main,
                                                        p: "16px",
                                                    },
                                                }}
                                            >
                                                <Markdown content={content} />
                                            </RegularText>
                                        )}
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default ColoredTextBlock;
