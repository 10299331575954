import { Markdown } from "@components/Markdown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { APITypes } from "utils/api.types";

export interface FaqBlockProps {
  block: APITypes.Components.ContentBlocks.FaqBlock;
}

const FaqBlock = ({ block }: FaqBlockProps) => {
  const { textBlock, faq } = block;

  return (
    <Box pt={10} pb={20}>
      <Container maxWidth={"md"}>
        <Grid
          container
          sx={{
            justifyContent: "center",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component={"div"}
              sx={{
                fontSize: {
                  lg: "30px",
                  xs: "24px",
                },
                fontWeight: {
                  lg: 700,
                  xs: 600,
                },
              }}
            >
              <Markdown content={textBlock.title} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component={"div"}
              sx={{
                fontSize: "21px",
                fontWeight: 400,
              }}
            >
              <Markdown content={textBlock.description} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {faq.collapsibleList.list.map((item, i) => (
              <div key={i}>
                <Accordion
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        color: "#E6539B",

                        "& .MuiSvgIcon-root": {
                          fontSize: 40,
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "27px",
                        fontWeight: "700",
                        paddingLeft: 2,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component={"div"} sx={{ paddingLeft: 8 }}>
                      <Markdown content={item.description} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default FaqBlock;
