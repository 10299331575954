import { colors } from "@components/Theme/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Link,
    List,
    Stack,
    Typography,
} from "@mui/material";
import { Image, Markdown } from "components";
import NextLink from "next/link";
import Carousel from "react-material-ui-carousel";
import { APITypes } from "utils/api.types";

export interface NewsPreviewProps {
    block: APITypes.Components.ContentBlocks.PreviewNewsBlock;
}

const NewsPreview = ({ block }: NewsPreviewProps) => {
    const { title, socialLinks, socialLinksTitle, newsPages } = block;
    return (
        <Box
            py={10}
            sx={{
                backgroundColor: "#FFF",
            }}
        >
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    <Box mb={6}>
                        <Typography variant="h2">{title}</Typography>
                    </Box>
                    <Grid
                        container
                        item
                        xs={12}
                        md={7}
                        justifyContent={"flex-end"}
                        sx={{
                            display: {
                                xs: "none",
                                md: "flex",
                            },
                        }}
                    >
                        <Box
                            mb={5}
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                alignItems: "center",
                            }}
                        >
                            <Box mt={1}>
                                <List>
                                    {socialLinks.map((item, ind) => (
                                        <Link
                                            href={item.href ?? "/"}
                                            underline="none"
                                            key={ind + item.name}
                                            sx={{
                                                mx: 1,
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Image
                                                alt={item.icon?.name}
                                                image={item.icon}
                                                style={{
                                                    height: "26px",
                                                    width: "26px",
                                                    padding: "4px",
                                                    backgroundColor:
                                                        colors.greyColor.main,
                                                }}
                                            />
                                        </Link>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction={"row"}
                    spacing={2}
                    sx={{
                        display: {
                            md: "flex",
                            xs: "none",
                        },
                        justifyContent: {
                            lg: "start",
                            xs: "center",
                        },
                    }}
                >
                    {newsPages.map((news, i) => {
                        return (
                            <Grid item lg={4} md={4} sm={12} xs={12} key={i}>
                                <PreviewNewsCard
                                    news={news}
                                    isRichText={true}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <NewsCarousel block={block} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "38px",
                    }}
                >
                    <Button
                        component={NextLink}
                        variant="contained"
                        href={`${block.button.href}`}
                    >
                        {block.button.title}
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};
export default NewsPreview;

export interface NewsCarouselProps {
    block: APITypes.Components.ContentBlocks.PreviewNewsBlock;
}

const NewsCarousel = ({ block }: NewsCarouselProps) => {
    return (
        <Box
            sx={{
                backgroundColor: "#FFF",
                display: {
                    md: "none",
                    xs: "block",
                },
            }}
        >
            <Container maxWidth="lg">
                <Grid container justifyContent={"center"}>
                    <Carousel
                        swipe={true}
                        autoPlay={false}
                        sx={{
                            width: "455px",
                        }}
                        navButtonsAlwaysInvisible={true}
                        indicatorIconButtonProps={{
                            style: {
                                color: "grey",
                                padding: "1px",
                                marginTop: "40px",
                            },
                        }}
                        activeIndicatorIconButtonProps={{
                            style: {
                                color: "black",
                            },
                        }}
                        navButtonsProps={{
                            style: {
                                borderRadius: 0,
                                backgroundColor: "transparent",
                                color: "black",
                            },
                        }}
                    >
                        {block.newsPages.map((news, i) => {
                            return (
                                <PreviewNewsCard
                                    news={news}
                                    key={i}
                                    isRichText={false}
                                />
                            );
                        })}
                    </Carousel>
                </Grid>
            </Container>
        </Box>
    );
};

interface PreviewNewsCardProps {
    news: {
        title: string;
        description: string;
        image: APITypes.Components.Media;
        slug: string;
    };
    isRichText: boolean;
}

const PreviewNewsCard = ({ news, isRichText }: PreviewNewsCardProps) => {
    return (
        <NextLink
            style={{
                height: "100%",
                fontSize: "18px",
                fontWeight: 700,
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                flex: 1,
            }}
            href={news.slug}
        >
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flex: 1,
                    maxWidth: "455px",
                    boxShadow: "none",
                    borderRadius: 0,
                    background: "#FAFAFA",
                }}
            >
                <Stack>
                    <Image
                        alt={news.image?.name}
                        image={news.image}
                        style={{
                            objectFit: "cover",
                        }}
                        layout="responsive"
                    />
                    <CardContent sx={{ py: 4 }}>
                        <Typography variant="h3">{news.title}</Typography>
                        <Typography component={"div"} color={"primary"}>
                            {isRichText ? (
                                <Markdown content={news.description} />
                            ) : (
                                news.description
                            )}
                        </Typography>
                    </CardContent>
                </Stack>
                <Stack
                    flexDirection={"row"}
                    alignItems={"end"}
                    justifyContent={"end"}
                >
                    <Button
                        sx={{ px: 1, py: 0.5 }}
                        variant="contained"
                        component={NextLink}
                        href={news.slug}
                    >
                        <ArrowForwardIcon
                            sx={{ p: 0, m: 0, width: "20px" }}
                            fontSize="small"
                        />
                    </Button>
                </Stack>
            </Card>
        </NextLink>
    );
};
