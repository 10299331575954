import { Markdown } from "@components/Markdown";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import StopIcon from "@mui/icons-material/Stop";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Field, Formik, useFormik } from "formik";
import { useState } from "react";
import { APITypes } from "utils/api.types";
import { submitForm, uploadFile } from "utils/fetchingFunctions";
import { FILE_SIZE, validationSchema } from "./ContactITCluster.util";
import { colors } from "@components/Theme/colors";
import NearMeIcon from "@mui/icons-material/NearMe";

interface ContactFormProps {
    form: APITypes.Components.ContactForm;
}

export default function ContactForm({ form }: ContactFormProps) {
    const {
        phoneNumberPlaceholder,
        firstNamePlaceholder,
        emailPlaceholder,
        selectOptions,
        selectPlaceholder,
        checkBox,
        submitButton,
        cvPlaceholder,
    } = form;
    const [isCVFileUploaded, setIsCVFileUploaded] = useState(false);
    const [isFormSent, setFormSent] = useState(false);
    const [isFormSending, setIsFormSending] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            number: "",
            track: "",
            cv: undefined as File | undefined,
            contact: false,
            privacy: false,
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                setIsFormSending(true);
                const formData = new FormData();
                formData.append("files", values.cv as File);

                const resFile = await uploadFile(formData);

                if (resFile.status !== 200) {
                    setIsFormSending(false);
                    throw new Error("File upload failed");
                }

                const fileData = await resFile.json();

                const submissionData = {
                    ...values,
                    cvId: fileData[0]?.id,
                    cvName: fileData[0]?.name,
                };

                const res = await submitForm("IT-cluster", submissionData);
                if (res.status === 200) {
                    resetForm();
                    setFormSent(true);
                    (window as any).dataLayer.push({
                        event: "it form sent",
                    });
                } else {
                    setIsFormSending(false);
                    console.error(res);
                }
            } catch (error) {
                setIsFormSending(false);
                console.error(error);
            }
        },
    });

    if (isFormSent) {
        return (
            <div>
                <Box>
                    <Typography variant="h2">
                        {submitButton.successMessage}
                    </Typography>
                    <Typography variant="h3">
                        {submitButton.successDescription}
                    </Typography>
                </Box>
            </div>
        );
    }

    return (
        <div>
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    number: "",
                    track: "",
                }}
                onSubmit={() => {}}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <InputLabel>{firstNamePlaceholder} *</InputLabel>
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>{emailPlaceholder} *</InputLabel>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>{phoneNumberPlaceholder} *</InputLabel>
                            <TextField
                                fullWidth
                                id="number"
                                name="number"
                                value={formik.values.number}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.number &&
                                    Boolean(formik.errors.number)
                                }
                                helperText={
                                    formik.touched.number &&
                                    formik.errors.number
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>{selectPlaceholder} *</InputLabel>
                            <Select
                                sx={{
                                    "& .MuiSelect-select .notranslate::after":
                                        selectPlaceholder
                                            ? {
                                                  content: `"${selectPlaceholder}"`,
                                                  opacity: 0.42,
                                              }
                                            : {},
                                }}
                                fullWidth
                                name="track"
                                id="track"
                                value={formik.values.track}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.track &&
                                    Boolean(formik.errors.track)
                                }
                            >
                                {selectOptions.map((option, i) => (
                                    <MenuItem key={i} value={option.title}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText sx={{ color: "red", ml: "14px" }}>
                                {formik.touched.track && formik.errors.track}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>{cvPlaceholder} *</InputLabel>
                            <Button
                                fullWidth
                                variant="contained"
                                component="label"
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    position: "relative",
                                }}
                                endIcon={
                                    isCVFileUploaded ? (
                                        <CheckIcon />
                                    ) : (
                                        <AddIcon />
                                    )
                                }
                            >
                                <input
                                    name="cv"
                                    id="cv"
                                    accept="doc,xls,pdf,txt,htm,html,rtf,docx,xlsx,jpg,ico,jpeg,png,eml,emlx,msg,mbx,heic,heif,odt,ods,odp"
                                    type="file"
                                    size={FILE_SIZE}
                                    hidden
                                    onChange={(event) => {
                                        const cvFile =
                                            event?.target?.files != null &&
                                            event.target.files[0];
                                        cvFile != null &&
                                            formik.setFieldValue("cv", cvFile);
                                        setIsCVFileUploaded(true);
                                    }}
                                />

                                {isCVFileUploaded ? (
                                    <></>
                                ) : (
                                    <>{cvPlaceholder}</>
                                )}
                            </Button>
                            <FormHelperText
                                sx={{
                                    color: "red",
                                    margin: ".5rem 0 0 0",
                                }}
                            >
                                {formik.touched.cv && formik.errors.cv}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack>
                                {checkBox.map((checkbox, i) => (
                                    <Stack key={i}>
                                        <Field
                                            sx={{
                                                "& .MuiFormControlLabel-label":
                                                    {
                                                        fontSize: "16px",
                                                    },
                                            }}
                                            type="checkbox"
                                            name={
                                                i === 0 ? "contact" : "privacy"
                                            }
                                            as={FormControlLabel}
                                            control={
                                                <Checkbox
                                                    checkedIcon={
                                                        <StopIcon
                                                            sx={{
                                                                fontSize:
                                                                    "24px",
                                                                border: 0.2,
                                                                borderRadius: 0.5,
                                                                color: colors
                                                                    .primary
                                                                    .main,
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                            checked={
                                                formik.values[
                                                    i === 0
                                                        ? "contact"
                                                        : "privacy"
                                                ]
                                            }
                                            label={
                                                checkbox.label ? (
                                                    <Markdown
                                                        content={checkbox.label}
                                                    />
                                                ) : (
                                                    checkbox.Name
                                                )
                                            }
                                            onChange={formik.handleChange}
                                        />
                                        <FormHelperText
                                            sx={{ color: "red", ml: "14px" }}
                                        >
                                            {formik.touched[
                                                i === 0 ? "contact" : "privacy"
                                            ] &&
                                                formik.errors[
                                                    i === 0
                                                        ? "contact"
                                                        : "privacy"
                                                ]}
                                        </FormHelperText>
                                    </Stack>
                                ))}
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack gap={2}>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 400,
                                    }}
                                >
                                    {submitButton.subTitle}
                                </Typography>
                                <Button
                                    size="large"
                                    variant="contained"
                                    color="green"
                                    type="submit"
                                    sx={{ width: "fit-content" }}
                                    disabled={
                                        formik.isSubmitting || isFormSending
                                    }
                                    endIcon={<NearMeIcon />}
                                >
                                    {submitButton.title}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Formik>
        </div>
    );
}
