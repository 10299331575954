import { colors } from "@components/Theme/colors";
import TitleBiggest from "@components/typography/TitleBiggest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    keyframes,
    Slider,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Image, Markdown } from "components";
import NextLink from "next/link";
import { useState } from "react";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";

const spin = keyframes`
  from {
    height: 0px;
  }
`;

export interface ConsultHeroProps {
    block: APITypes.Components.ContentBlocks.ConsultHero;
}

const ConsultHero = ({ block }: ConsultHeroProps) => {
    const { carouselItems, heroSquareBlock, title } = block;
    const theme = useTheme();
    const isExtraSmallSize = useMediaQuery(theme.breakpoints.down("md"));
    const [activeCarouselIndex, setActiveCarouselIndex] = useState<number>(3);

    const images = carouselItems;

    return (
        <Box
            pb={{ xs: 4, md: 20 }}
            sx={{
                backgroundColor: colors.greyColor.light,
            }}
        >
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <Container
                    maxWidth="xl"
                    disableGutters
                    sx={{
                        position: "relative",
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "1fr",
                    }}
                >
                    {!isExtraSmallSize && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 20,
                                left: 40,
                                maxWidth: "600px",
                            }}
                        >
                            <Typography
                                variant="h1"
                                component={"div"}
                                sx={{
                                    color: colors.white.main,
                                    "& span": {
                                        fontFamily: "Proxima Soft",
                                        fontStyle: "normal",
                                    },
                                }}
                            >
                                <SanitizeString content={title} />
                            </Typography>
                        </Box>
                    )}
                    <Stack
                        direction={"row"}
                        width={"100%"}
                        alignItems={"end"}
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                background: colors.green.main,
                                height: { xs: "40px", md: "70px" },
                                width: "30%",
                                animation: `${spin} 2s ease`,
                            }}
                        />
                        <Box
                            sx={{
                                background: colors.greyColor.main,
                                height: { xs: "20px", md: "50px" },
                                width: "25%",
                                animation: `${spin} 2s ease`,
                            }}
                        />
                        <Box
                            sx={{
                                background: colors.secondary.main,
                                height: { xs: "15px", md: "20px" },
                                width: "40%",
                                animation: `${spin} 2s ease`,
                            }}
                        />
                        <Box
                            sx={{
                                background: colors.pink.main,
                                height: { xs: "100px", md: "300px" },
                                width: "5%",
                                animation: `${spin} 2s ease`,
                            }}
                        />
                    </Stack>

                    {images.map((image, i) => {
                        return (
                            <Image
                                key={i}
                                image={image.image}
                                alt={image.image.name}
                                style={{
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    gridArea: "1 / 1",
                                    opacity: activeCarouselIndex === i ? 1 : 0,
                                    width: "100%",
                                    maxHeight: "801.4px",
                                    height: isExtraSmallSize
                                        ? "460px"
                                        : undefined,
                                    objectFit: "cover",
                                    objectPosition: "center center",
                                }}
                            />
                        );
                    })}
                    <Container
                        maxWidth="xl"
                        sx={{
                            position: "relative",
                        }}
                    >
                        <Grid
                            sx={{
                                position: "absolute",
                                bottom: { xs: 80, md: 140 },
                                left: 0,
                                width: "100%",
                                marginRight: "auto",
                                marginLeft: "auto",
                            }}
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            gap={4}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    width: isExtraSmallSize ? "100%" : "80%",
                                    height: isExtraSmallSize
                                        ? "340px"
                                        : "fit-content",
                                    mb: "20px",
                                }}
                            >
                                <Slider
                                    orientation={
                                        isExtraSmallSize
                                            ? "vertical"
                                            : "horizontal"
                                    }
                                    onChange={(
                                        event: Event,
                                        newValue: number | number[],
                                        activeThumb: number,
                                    ) =>
                                        Array.isArray(newValue)
                                            ? setActiveCarouselIndex(
                                                  newValue[0] / 10 - 1,
                                              )
                                            : setActiveCarouselIndex(
                                                  newValue / 10 - 1,
                                              )
                                    }
                                    sx={{
                                        margin: 0,
                                        ".MuiSlider-thumb": {
                                            color: colors.green.main,
                                        },
                                        ".MuiSlider-rail": {
                                            color: "#fff",
                                            opacity: 100,
                                            height: isExtraSmallSize
                                                ? "340px"
                                                : "2px",
                                            width: isExtraSmallSize
                                                ? "1.5px"
                                                : "100%",
                                        },
                                        ".MuiSlider-track": {
                                            color: "transparent",
                                            width: "1px",
                                        },
                                        ".MuiSlider-mark": {
                                            borderRadius: "50%",
                                            color: "#fff",
                                            height: isExtraSmallSize
                                                ? "6px"
                                                : "8px",
                                            width: isExtraSmallSize
                                                ? "6px"
                                                : "8px",
                                        },
                                        ".MuiSlider-markLabel": {
                                            color: "#fff",
                                            fontSize: {
                                                xs: "16px",
                                                lg: "18px",
                                            },
                                            fontWeight: 700,
                                        },
                                        [`[data-index="${activeCarouselIndex}"]`]:
                                            {
                                                color: colors.green.main,
                                                fontSize: {
                                                    xs: "20px",
                                                    lg: "30px",
                                                },
                                                fontWeight: 700,
                                            },
                                    }}
                                    defaultValue={40}
                                    valueLabelFormat={() =>
                                        carouselItems[activeCarouselIndex].text
                                    }
                                    valueLabelDisplay="off"
                                    step={0}
                                    min={10}
                                    marks={carouselItems.map((item, i) => ({
                                        value: (i + 1) * 10,
                                        label: item.text,
                                    }))}
                                    max={carouselItems.length * 10}
                                />
                            </Box>
                        </Grid>
                    </Container>
                </Container>
            </Box>
            <Stack flexDirection={"column"}>
                <Container maxWidth="lg">
                    <Stack
                        flexDirection={{ xs: "column", md: "row" }}
                        gap={{ xs: 2, md: 4 }}
                        py={{ xs: 4, md: 10 }}
                    >
                        <Stack flex={1}>
                            <TitleBiggest
                                sx={{
                                    textAlign: { xs: "left", md: "right" },
                                }}
                                text={heroSquareBlock.title}
                                titleSize={"h2"}
                            />
                        </Stack>
                        <Stack flex={1}>
                            <Typography
                                component={"div"}
                                sx={{
                                    mt: { xs: 0, md: 8 },
                                }}
                            >
                                <Markdown
                                    content={heroSquareBlock.description}
                                />
                            </Typography>

                            {heroSquareBlock?.button && (
                                <Stack flexDirection={"row"}>
                                    <Button
                                        component={NextLink}
                                        variant="contained"
                                        size="large"
                                        href={heroSquareBlock.button.href}
                                    >
                                        {heroSquareBlock.button.title}
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Container>
                {block.blueLink && (
                    <Container maxWidth="xl" disableGutters>
                        <Stack
                            flexDirection={"row"}
                            justifyContent={"end"}
                            sx={{
                                mt: -10,
                                display: {
                                    xs: "none",
                                    lg: "flex",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: "282px",
                                    padding: "18px",
                                    backgroundColor: colors.green.main,
                                    color: colors.primary.main,
                                    fontSize: "21px",
                                    fontWeight: 700,
                                    textAlign: "center",
                                    zIndex: 2,

                                    a: {
                                        color: colors.secondary.main,
                                    },
                                }}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    gap={1}
                                >
                                    <Typography
                                        component={"div"}
                                        sx={{
                                            "& p": {
                                                margin: 0,
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        <Markdown
                                            content={block.blueLink}
                                            isOpenLinkNewTab={false}
                                        />
                                    </Typography>
                                    <Divider
                                        orientation="vertical"
                                        variant="middle"
                                        flexItem
                                        sx={{
                                            backgroundColor:
                                                colors.primary.main,
                                        }}
                                    />
                                    <VisibilityIcon fontSize="small" />
                                </Stack>
                            </Box>
                        </Stack>
                    </Container>
                )}
            </Stack>
        </Box>
    );
};
export default ConsultHero;
