import { remark } from "remark";
import remarkHtml from "remark-html";
import remarkGfm from "remark-gfm";

export default async function markdownToHtml(
    markdown: string
): Promise<string> {
    const result = await remark()
        .use(remarkHtml)
        .use(remarkGfm)
        .process(markdown);
    return result.toString();
}
