import { Card, CardContent, CardHeader, Container } from "@mui/material";

export interface NotFoundBlock {
    title: string;
    content?: string;
}

export const NotFoundBlock = ({ title, content }: NotFoundBlock) => {
    return (
        <Container sx={{ my: 10 }}>
            <Card>
                <CardHeader title={title} />
                {content && <CardContent>{content}</CardContent>}
            </Card>
        </Container>
    );
};
