"use client";
import DOMPurify from "isomorphic-dompurify";

import { Box } from "@mui/material";
import theme from "@components/Theme/theme";
import { colors } from "@components/Theme/colors";

type TContext = "layout" | "richText";

interface ISanitizeStringProps {
    content: string;
    context?: TContext;
}

const sanitizeStringStyles = (context: TContext) => {
    return {
        "*": {
            fontSize: "inherit",
            fontWeight: "inherit",
            fontFamily: "inherit",
        },

        "& h1": {
            ...theme.typography.h1,
        },

        "& h2": {
            ...theme.typography.h2,
        },

        "& h3": {
            ...theme.typography.h3,
        },

        "& h4": {
            ...theme.typography.h4,
        },

        "& h5": {
            ...theme.typography.h5,
        },

        "& h6": {
            ...theme.typography.h6,
        },

        "& p": {
            fontSize: "inherit",
            fontWeight: "inherit",
            fontFamily: "inherit",
        },

        "& figure": {
            display: "flex",
            justifyContent: "center",
        },

        "& a": {
            color: colors.secondary.main,
            fontWeight: "700",
        },

        "& strong": {
            fontWeight: "700",
        },

        ...(context === "layout" && {
            "& h1, h2, h3, h4, h5, h6, p": {
                margin: "0",
            },
        }),
    };
};

export const SanitizeString = ({
    content,
    context = "layout",
}: ISanitizeStringProps) => {
    const cleanText = DOMPurify.sanitize(content);

    const cleanerText = cleanText
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&")
        .replaceAll("<a", `<a target="_blank"`);

    if (cleanerText.length > 0) {
        return (
            <Box
                sx={sanitizeStringStyles(context)}
                dangerouslySetInnerHTML={{
                    __html: cleanerText,
                }}
            />
        );
    } else {
        return <></>;
    }
};
