import { Grid, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import { Image, Markdown } from "..";
import { colors } from "@components/Theme/colors";

export interface IIconBlockAdaptableProps {
    direction: "row" | "column";
    amountOfBlocks: number;
    image: APITypes.Components.Media;
    title: string;
    description: string;
    //TODO
    alignment: any;
}

export default function IconBlockAdaptable({
    direction,
    amountOfBlocks,
    alignment,
    image,
    title,
    description,
}: IIconBlockAdaptableProps) {
    return (
        <Grid
            sx={{
                display: "flex",
                flexDirection: {
                    lg: "row",
                    xs: "column",
                },
                pb: { xs: 4, lg: 8 },
            }}
            container
            item
            xs={12}
            lg={4}
            justifyContent="center"
            alignItems={alignment}
            direction={"column"}
        >
            <Stack
                flexDirection={direction}
                alignItems={direction === "column" ? "center" : "start"}
                gap={2}
            >
                <Image image={image} alt={image?.name} />
                <Grid item xs={12} md={8}>
                    <Typography
                        align={alignment}
                        component={"div"}
                        variant="h3"
                        sx={{
                            "& p": {
                                margin: "0",
                            },
                        }}
                    >
                        <Markdown content={title} />
                    </Typography>

                    <Typography
                        align={alignment}
                        component={"div"}
                        sx={{
                            fontSize: "18px",
                            fontWeight: {
                                xs: 400,
                            },
                        }}
                    >
                        <Markdown content={description} />
                    </Typography>
                </Grid>
            </Stack>
        </Grid>
    );
}
