import { colors } from "@components/Theme/colors";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Image } from "components";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";

export interface ImageWithContentProps {
    block: APITypes.Components.ContentBlocks.ImageWithContent;
}

const ImageWithContent = ({ block }: ImageWithContentProps) => {
    const { image, title, subTitle, content, backgroundColor, imagePosition } =
        block;

    const isLeft = imagePosition === "left";
    return (
        <Container
            maxWidth={"xl"}
            sx={{
                py: { xs: 8, lg: "120px" },
                backgroundColor: backgroundColor ?? colors.white.main,
            }}
        >
            <Stack
                flexDirection={{
                    xs: "column",
                    md: isLeft ? "row" : "row-reverse",
                }}
                justifyContent={"stretch"}
                alignItems={"center"}
                gap={{ xs: "20px", md: "100px" }}
                sx={{
                    height: "100%",
                }}
            >
                <Stack flex={1} height={"100%"} width={"100%"}>
                    <Image
                        image={image}
                        alt={image.name}
                        style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                        }}
                    />
                </Stack>
                <Stack
                    flex={1}
                    height={"100%"}
                    gap={{ xs: "10px", md: "38px" }}
                >
                    <Typography
                        variant="h3"
                        style={{
                            fontWeight: 400,
                        }}
                    >
                        {subTitle}
                    </Typography>
                    <Typography variant="h2">
                        <SanitizeString content={title} />
                    </Typography>
                    <Typography component={"div"}>
                        <SanitizeString content={content} />
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    );
};

export default ImageWithContent;
