import { colors } from "@components/Theme/colors";
import { APITypes } from "utils/api.types";

export const checkboxFieldStyles = {
    marginLeft: "0",
    marginRight: "0",
    "& a": {
        color: colors.secondary.main,
        textDecoration: "none",
    },
    "& .MuiFormControlLabel-label": {
        fontSize: "16px",
    },
};

export const checkboxIconStyles = (
    form: APITypes.Components.ContentBlocks.LandingPageContactFrom,
) => {
    return {
        fontSize: "24px",
        border: 0.2,
        borderRadius: 0.5,
        color: colors.primary.main,
        backgroundColor: "white",
        borderColor: "white",
    };
};
