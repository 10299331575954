export const textFieldStyle = {
    border: 0,
    borderRadius: "4px",
    "& fieldset": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRadius: "4px",
    },
    input: {
        borderRadius: "4px",
        backgroundColor: "white",
        "&::placeholder": {
            color: "#093950",
        },
    },
    "& .MuiSelect-select ": {
        backgroundColor: "white",
        "&::placeholder": {
            color: "#093950",
        },
    },
    "& .MuiInputBase-multiline": {
        backgroundColor: "white",
    },
};

export const attachmentButtonStyle = {
    height: "56px",
    backgroundColor: "#3590D2",
    boxShadow: "none",
    textTransform: "none",
    border: "2px dashed #FFFFFF",
    borderRadius: 0,
};

export const CV_BUTTON_HOLDER_STYLE = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    right: 0,
    "& div:not(#cxsWrapper):not(#cxsSection_4):not(#cxsSection_0):not(#cxsFieldContainer_0):not(.cxsFieldContainer)":
        {
            display: "none",
        },
    "& #cxsSectionHeader_0 #cxsPageHeader ": {
        display: "none",
    },

    "& .cxsSectionHeader": {
        display: "none",
    },

    "& .cxsPageHeader": {
        display: "none",
    },
    "& #cxsSectionHeader_0": {
        display: "none",
    },
    "& #cxsApplyButton": {
        display: "none",
        visibility: "hidden",
    },
    "& #cxsFieldAfterLabel_0": {
        display: "none",
    },
    "& #cxsFieldLabel_15": {
        display: "none",
    },
    "& #cxsFieldAfterLabel_15": {
        display: "none",
    },
    "& #cxsFieldLabel_16": {
        display: "none",
        visibility: "none",
    },
    "& #cxsFieldAfterLabel_16": {
        display: "none",
    },
    "& .cxsTextkernelSection": {
        display: "none",
    },
    "& #cxsFormHolder": {
        display: "none",
    },
    "& #cxsFileRemove15": {
        display: "none",
        visibility: "hidden",
    },
    "& #cxsFileName15": {
        display: "none",
        visibility: "hidden",
    },
    "& #cxsFileUploadFrame_15": {
        width: "100%",
        height: "100%",
        top: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
    },
    "& #cxsFieldContainer_16": {
        display: "none",
        visibility: "hidden",
    },
};

export const ATTACHMENT_BUTTON_HOLDER_STYLE = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    right: 0,
    "& div:not(#cxsWrapper):not(#cxsSection_4):not(#cxsSection_0):not(#cxsFieldContainer_0):not(.cxsFieldContainer)":
        {
            display: "none",
        },
    "& #cxsSectionHeader_0 #cxsPageHeader ": {
        display: "none",
    },

    "& .cxsSectionHeader": {
        display: "none",
    },

    "& .cxsPageHeader": {
        display: "none",
    },
    "& #cxsSectionHeader_0": {
        display: "none",
    },
    "& #cxsApplyButton": {
        display: "none",
        visibility: "hidden",
    },
    "& #cxsFieldAfterLabel_0": {
        display: "none",
    },
    "& #cxsFieldLabel_16": {
        display: "none",
    },
    "& #cxsFieldAfterLabel_16": {
        display: "none",
    },
    "& #cxsFieldLabel_15": {
        display: "none",
    },
    "& #cxsFieldAfterLabel_15": {
        display: "none",
    },
    "& .cxsTextkernelSection": {
        display: "none",
    },
    "& #cxsFormHolder": {
        display: "none",
    },
    "& #cxsFileRemove16": {
        display: "none",
        visibility: "hidden",
    },
    "& #cxsFileName16": {
        display: "none",
        visibility: "hidden",
    },
    "& #cxsFileUploadFrame_16": {
        width: "100%",
        height: "100%",
        top: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
    },
    "& #cxsFieldContainer_15": {
        display: "none",
        visibility: "hidden",
    },
};
