import { BreadCrumbs } from "@components/BreadCrumbs/BreadCrumbs";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Pagination,
    Typography,
} from "@mui/material";
import { Image, Markdown } from "components";
import NextLink from "next/link";
import { ChangeEvent, useState } from "react";
import { APITypes } from "utils/api.types";

export interface NewsPageBlockProps {
    block: APITypes.Components.ContentBlocks.NewsPageBlock;
}

const NewsPageBlock = ({ block }: NewsPageBlockProps) => {
    const { newsPages } = block;
    const [page, setPage] = useState(1);
    const [cardsPerPage] = useState(6);

    const startIndex = (page - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const cardsToDisplay = block.newsPages.slice(startIndex, endIndex);

    const handleChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <Box
            py={"32px"}
            sx={{
                backgroundColor: "#F3F3F3",
            }}
        >
            <Container maxWidth="lg">
                <Box sx={{ mb: "32px" }}>
                    <BreadCrumbs />
                </Box>
                <Grid
                    container
                    spacing={4}
                    sx={{
                        display: "flex",
                        flexDirection: {
                            md: "row",
                            xs: "column",
                        },
                        justifyContent: "center",
                        alignItems: { xs: "center", md: "stretch" },
                    }}
                >
                    {cardsToDisplay.map((news, i) => {
                        return (
                            <Grid item lg={4} md={4} sm={12} xs={12} key={i}>
                                <PreviewNewsCard
                                    news={news}
                                    isRichText={true}
                                />
                            </Grid>
                        );
                    })}
                    <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <Pagination
                            count={newsPages.length / 6}
                            page={page}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default NewsPageBlock;

interface PreviewNewsCardProps {
    news: {
        title: string;
        description: string;
        image: APITypes.Components.Media;
        slug: string;
    };
    isRichText: boolean;
}

const PreviewNewsCard = ({ news, isRichText }: PreviewNewsCardProps) => {
    return (
        <NextLink
            style={{
                height: "100%",
                fontSize: "18px",
                fontWeight: 700,
                textDecoration: "none",
                display: "flex",
                flexDirection: "column",
                flex: 1,
            }}
            href={news.slug}
        >
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    maxWidth: "455px",
                    boxShadow: "none",
                    borderRadius: 0,
                }}
            >
                <Image
                    alt={news.image?.name}
                    image={news.image}
                    style={{
                        minHeight: "247px",
                        maxHeight: "247px",
                        maxWidth: "455px",
                    }}
                    layout="responsive"
                />
                <CardContent>
                    <Typography
                        sx={{
                            fontSize: "27px",
                            fontWeight: 700,
                            lineHeight: "100%",
                        }}
                    >
                        {news.title}
                    </Typography>
                    <Typography
                        component={"div"}
                        sx={{
                            marginTop: 2,
                            fontSize: "18px",
                            fontWeight: 400,
                        }}
                    >
                        {isRichText ? (
                            <Markdown content={news.description} />
                        ) : (
                            news.description
                        )}
                    </Typography>
                </CardContent>
                <CardActions
                    sx={{
                        mt: "auto",
                    }}
                >
                    <Box
                        sx={{
                            color: "#54B8E3",
                            paddingY: {
                                xs: 8,
                                md: 0,
                            },
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <ArrowForwardIcon />
                    </Box>
                </CardActions>
            </Card>
        </NextLink>
    );
};
