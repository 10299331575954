import { Box, Grid, Link, List, Typography } from "@mui/material";
import { Image, Markdown } from "components";
import { APITypes } from "utils/api.types";

export interface GridBlockProps {
    block: APITypes.Components.ContentBlocks.GridBlock;
}

const GridBlock = ({ block }: GridBlockProps) => {
    const { column } = block;

    return (
        <Box
            sx={{
                backgroundColor: "#F3F3F3",
            }}
        >
            <Box>
                <Grid
                    container
                    wrap="nowrap"
                    sx={{
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                    }}
                    justifyContent={"center"}
                    spacing={0}
                >
                    {column.map((col, i) => {
                        return (
                            <Grid
                                container
                                wrap="nowrap"
                                item
                                xs={12}
                                md={4}
                                direction={"column"}
                                justifyContent={"center"}
                                key={i}
                            >
                                {col.columnTextBlock && (
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        md={getGridSize(
                                            col.columnTextBlock.size,
                                        )}
                                        justifyContent={"flex-end"}
                                        alignContent={"center"}
                                        sx={{
                                            backgroundColor:
                                                col.columnTextBlock
                                                    .hexBackgroundColor,
                                            color: col.columnTextBlock
                                                .hexTextColor,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                padding: {
                                                    xs: "20px",
                                                    lg: "30px",
                                                },
                                            }}
                                        >
                                            <Typography
                                                component={"div"}
                                                sx={{
                                                    fontSize: {
                                                        lg: "30px",
                                                        xs: "24px",
                                                    },
                                                    fontWeight: {
                                                        lg: 700,
                                                        xs: 600,
                                                    },
                                                }}
                                            >
                                                <Markdown
                                                    content={
                                                        col.columnTextBlock
                                                            .title
                                                    }
                                                />
                                            </Typography>
                                            <Typography
                                                component={"div"}
                                                sx={{
                                                    fontSize: {
                                                        lg: "18px",
                                                        xs: "16px",
                                                    },
                                                    fontWeight: 400,
                                                }}
                                            >
                                                <Markdown
                                                    content={
                                                        col.columnTextBlock
                                                            .description
                                                    }
                                                />
                                            </Typography>
                                        </Box>
                                    </Grid>
                                )}
                                {col.columnImage &&
                                    col.columnImage.map((colImg, index) => (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={getGridSize(colImg.size)}
                                            key={index + colImg.image.name}
                                        >
                                            <Image
                                                image={colImg.image}
                                                alt={colImg.image.name}
                                                layout={"responsive"}
                                            />
                                        </Grid>
                                    ))}
                                {col.columnSocialBlock && (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={getGridSize(
                                            col.columnSocialBlock.size,
                                        )}
                                        sx={{
                                            backgroundColor: "white",
                                            minHeight: "68px",
                                        }}
                                        justifyContent={"flex-end"}
                                        alignContent={"center"}
                                    >
                                        <Typography
                                            sx={{
                                                marginRight: 1,
                                                fontSize: {
                                                    xs: "24px",
                                                    md: "19px",
                                                    lg: "24px",
                                                },
                                                fontWeight: {
                                                    lg: 700,
                                                    xs: 600,
                                                },
                                            }}
                                        >
                                            {col.columnSocialBlock.title}
                                        </Typography>
                                        {col.columnSocialBlock.socialLink
                                            .length > 0 && (
                                            <Box>
                                                <List sx={{ marginRight: 1 }}>
                                                    {col.columnSocialBlock.socialLink.map(
                                                        (item, ind) => (
                                                            <Link
                                                                target="_blank"
                                                                href={
                                                                    item.href ??
                                                                    "/"
                                                                }
                                                                underline="none"
                                                                key={
                                                                    ind +
                                                                    item.name
                                                                }
                                                                sx={{
                                                                    fill: "#3EA4DC",
                                                                    marginRight: 1,
                                                                }}
                                                            >
                                                                <Image
                                                                    alt={
                                                                        item
                                                                            .icon
                                                                            ?.name
                                                                    }
                                                                    image={
                                                                        item.icon
                                                                    }
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "26px",
                                                                        fill: "#3EA4DC",
                                                                    }}
                                                                />
                                                            </Link>
                                                        ),
                                                    )}
                                                </List>
                                            </Box>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
};
export default GridBlock;

const getGridSize = (size: APITypes.Components.Size) => {
    switch (size) {
        case "h100":
            return 12;
        case "h60":
            return 7;
        case "h40":
            return 5;
        case "h20":
            return 2;
        default:
            return 12;
    }
};
