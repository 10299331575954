import ClusterHero from "@components/blocks/ClusterHero";
import ClustersPreview from "@components/blocks/ClustersPreview";
import CollapsibleList from "@components/blocks/CollapsibleList";
import ColoredTextBlock from "@components/blocks/ColoredTextBlock";
import ConsultHero from "@components/blocks/ConsultHero";
import FaqBlock from "@components/blocks/FaqBlock";
import GridBlock from "@components/blocks/GridBlock";
import IconBlockWithButton from "@components/blocks/IconBlockWithButton";
import IconsWithTextBlockProps from "@components/blocks/IconsWithTextblock";
import ImageBlock from "@components/blocks/ImageBlock";
import ImageWithContent from "@components/blocks/ImageWithContent";
import ImageWithTextBlock from "@components/blocks/ImageWithTextBlock";
import InfoIconBlocks from "@components/blocks/InfoIconBlocks";
import InternshipContactForm from "@components/blocks/InternshipContactForm";
import InternshipNewsBlock from "@components/blocks/InternshipNewsBlock";
import { JobsOverview, JobsPreview } from "@components/blocks/Jobs";
import LandingPageContactBlock from "@components/blocks/LandingPageContactBlock/LandingPageContactBlock";
import LandingPageHero from "@components/blocks/LandingPageHero/LandingPageHero";
import Locations from "@components/blocks/Locations";
import NewsPageBlock from "@components/blocks/NewsPageBlock";
import NewsPreview from "@components/blocks/NewsPreview";
import QuoteBlock from "@components/blocks/QuoteBlock";
import QuotesCarousel from "@components/blocks/QuotesCarousel";
import QuoteWithPicture from "@components/blocks/QuoteWithPicture";
import RotatingTextBlockButton from "@components/blocks/RotatingTextBlockButton";
import SpontaneousApplicationContactForm from "@components/blocks/SpontaneousApplicationContactForm";
import StepperFlowBlock from "@components/blocks/StepperFlowBlock";
import TextBlock from "@components/blocks/TextBlock";
import TextBlockButton from "@components/blocks/TextBlockButton";
import Footer from "@components/Footer/Footer";
import { Hero } from "@components/Hero/Hero";
import ContactITCluster from "@components/it-cluster-blocks/ContactITCluster";
import ExpectationsITCluster from "@components/it-cluster-blocks/ExpectationsITCluster";
import HeroITCluster from "@components/it-cluster-blocks/HeroITCluster";
import SubTrajectITCluster from "@components/it-cluster-blocks/SubTrajectITCluster";
import TrackITCluster from "@components/it-cluster-blocks/TrackITCluster";
import TrajectITCluster from "@components/it-cluster-blocks/TrajectITCluster";
import MovingBar from "@components/MovingBar/MovingBar";
import NavigationBar from "@components/NavigationBar/NavigationBar";
import { Box } from "@mui/material";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { NEXT_LOCALE_COOKIE, setNextLocaleCookieTo } from "utils/localization";

import { APITypes } from "../../utils/api.types";
import VacatureForm from "./VacatureForm/VacatureForm";
import ListBlock from "@components/blocks/ListBlock";
import ImageCarouselWithQuote from "@components/blocks/ImageCarouselWithQuote";
import TroevenBlock from "@components/blocks/TroevenBlock";
import VideoCarousel from "@components/blocks/VideoCarousel/VideoCarousel";
import VisionBlock from "@components/blocks/VisionBlock";
import StageCollapsibleList from "@components/blocks/StageCollapsibleList";

4;
interface IContentBuilderProps {
    contentBlocks: APITypes.Components.ContentBlocks[];
    slug: string;
    localizations: APITypes.Localization[];
    isScriptLoaded?: boolean;
    isMovingBarPresent?: boolean;
}

const ContentBuilder = ({
    contentBlocks,
    slug,
    localizations,
    isScriptLoaded,
    isMovingBarPresent = false,
}: IContentBuilderProps) => {
    let subFooter;
    let footer;
    const getComponent = (
        component: APITypes.Components.ContentBlocks,
        i: number,
    ) => {
        switch (component.__component) {
            case APITypes.Components.ContentBlocks.Types.NavigationBar:
                return (
                    <NavigationBar
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.NavigationBar
                        }
                        slug={slug}
                        localizations={localizations}
                    />
                );
            case APITypes.Components.ContentBlocks.Types.Hero:
                return (
                    <Hero
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.Hero
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.SubFooter:
                subFooter =
                    component as APITypes.Components.ContentBlocks.SubFooter;
                break;
            case APITypes.Components.ContentBlocks.Types.Footer:
                footer =
                    component as APITypes.Components.ContentBlocks.SubFooter;
                break;
            case APITypes.Components.ContentBlocks.Types.HeroITCluster:
                return (
                    <HeroITCluster
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.HeroITCluster
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.TrackITCluster:
                return (
                    <TrackITCluster
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.TrackITCluster
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.TrajectITCluster:
                return (
                    <TrajectITCluster
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.TrajectITCluster
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.SubTrajectITCluster:
                return (
                    <SubTrajectITCluster
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.SubTrajectITCluster
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ExpectationsITCluster:
                return (
                    <ExpectationsITCluster
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ExpectationsITCluster
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ContactITCluster:
                return (
                    <ContactITCluster
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ContactITCluster
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ClusterHero:
                return (
                    <ClusterHero
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ClusterHero
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.TextBlock:
                return (
                    <TextBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.TextBlockComponent
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.QuoteBlock:
                return (
                    <QuoteBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.QuoteBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ImageBlock:
                return (
                    <ImageBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ImageBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.BlogBlock:
                return (
                    <Box key={i}>
                        <ContentBuilder
                            contentBlocks={
                                (
                                    component as APITypes.Components.ContentBlocks.BlogBlock
                                ).blog.content
                            }
                            slug={slug}
                            localizations={localizations}
                        />
                    </Box>
                );
            case APITypes.Components.ContentBlocks.Types.NewsBlock:
                return (
                    <Box key={i}>
                        <ContentBuilder
                            contentBlocks={
                                (
                                    component as APITypes.Components.ContentBlocks.NewsBlock
                                ).news.content
                            }
                            slug={slug}
                            localizations={localizations}
                        />
                    </Box>
                );

            case APITypes.Components.ContentBlocks.Types.InternshipNews:
                return (
                    <InternshipNewsBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.InternshipNews
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.CollapsibleList:
                return (
                    <CollapsibleList
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.CollapsibleList
                        }
                    />
                );

            case APITypes.Components.ContentBlocks.Types.StepperFlow:
                return (
                    <StepperFlowBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.StepperFlow
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.InternshipForm:
                return (
                    <InternshipContactForm
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.InternshipForm
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.TextBlockButton:
                return (
                    <TextBlockButton
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.TextBlockButtonComponent
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.GridBlock:
                return (
                    <GridBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.GridBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.InfoIconBlocks:
                return (
                    <InfoIconBlocks
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.InfoIconBlocks
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.FaqBlock:
                return (
                    <FaqBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.FaqBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.JobsCards:
                return (
                    <JobsOverview
                        slug={slug}
                        localizations={localizations}
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.JobCards
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ConsultantsForm:
                return (
                    <SpontaneousApplicationContactForm
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.SpontaneousApplicationForm
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.JobsPreview:
                return (
                    <JobsPreview
                        slug={slug}
                        localizations={localizations}
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.JobsPreview
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.LandingPageContactFrom:
                return (
                    <LandingPageContactBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.LandingPageContactFrom
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.LandingPageHero:
                return (
                    <LandingPageHero
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.LandingPageHero
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ImageWithTextBlock:
                return (
                    <ImageWithTextBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ImageWithTextBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.IconsWithTextBlock:
                return (
                    <IconsWithTextBlockProps
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.IconsWithTextBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.QuotesCarousel:
                return (
                    <QuotesCarousel
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.QuotesCarousel
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.QuoteWithPicture:
                return (
                    <QuoteWithPicture
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.QuoteWithPicture
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ClustersPreview:
                return (
                    <ClustersPreview
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ClustersPreview
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ImageWithContent:
                return (
                    <ImageWithContent
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ImageWithContent
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.IconBlockWithButton:
                return (
                    <IconBlockWithButton
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.IconBlockWithButton
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.Locations:
                return (
                    <Locations
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.Locations
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.PreviewNewsBlock:
                return (
                    <NewsPreview
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.PreviewNewsBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types
                .RotatingTitleBlockWithButton:
                return (
                    <RotatingTextBlockButton
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.RotatingTitleBlockWithButton
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.VacatureForm:
                return (
                    <VacatureForm
                        key={i}
                        isScriptLoaded={isScriptLoaded ?? false}
                        block={
                            component as APITypes.Components.ContentBlocks.VacatureForm
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ColoredTextBlock:
                return (
                    <ColoredTextBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ColoredTextBlock
                        }
                    />
                );

            case APITypes.Components.ContentBlocks.Types.MovingBar:
                return (
                    <MovingBar
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.MovingBar
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.NewsPageBlock:
                return (
                    <NewsPageBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.NewsPageBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ConsultHero:
                return (
                    <ConsultHero
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ConsultHero
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.ListBlock:
                return (
                    <ListBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ListBlock
                        }
                    />
                );

            case APITypes.Components.ContentBlocks.Types.ImageCarouselWithQuote:
                return (
                    <ImageCarouselWithQuote
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.ImageCarouselWithQuote
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.TroevenBlock:
                return (
                    <TroevenBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.TroevenBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.VideoCarousel:
                return (
                    <VideoCarousel
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.VideoCarousel
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.VisionBlock:
                return (
                    <VisionBlock
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.VisionBlock
                        }
                    />
                );
            case APITypes.Components.ContentBlocks.Types.StageCollapsibleList:
                return (
                    <StageCollapsibleList
                        key={i}
                        block={
                            component as APITypes.Components.ContentBlocks.StageCollapsibleList
                        }
                    />
                );
            default:
                console.log(
                    `Could not find a corresponding component for ${component.__component}`,
                );
                return null;
        }
    };

    const { locale } = useRouter();
    useEffect(() => {
        if (locale) {
            const localeFromCookie = Cookies.get(NEXT_LOCALE_COOKIE);
            if (localeFromCookie !== locale) {
                setNextLocaleCookieTo(locale);
            }
        }
    }, [locale]);

    return (
        <div className="grid gap-20 sm:gap-30">
            {contentBlocks.map((c, i) => getComponent(c, i))}
            {(footer || subFooter) && (
                <Footer
                    footer={footer}
                    subFooter={subFooter}
                    isMovingBarPresent={isMovingBarPresent}
                    localizations={localizations}
                    slug={slug}
                />
            )}
        </div>
    );
};

export { ContentBuilder };
