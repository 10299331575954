import { Typography } from "@mui/material";

export interface ITitleBiggestProps {
    titleSize: "h1" | "h2";
    text: string;
    sx?: any;
}

export default function TitleBiggest({
    titleSize,
    text,
    sx,
}: ITitleBiggestProps) {
    return (
        <Typography
            component={"h1"}
            variant={titleSize}
            sx={{
                ...sx,
            }}
        >
            {text}
        </Typography>
    );
}
