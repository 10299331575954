import RegularText from "@components/typography/RegularText";
import { Grid } from "@mui/material";
import { APITypes } from "utils/api.types";
import { Image, Markdown } from "..";
import { colors } from "@components/Theme/colors";

export interface IIconBlockRegular {
    image: APITypes.Components.Media;
    description: string;
    leftColumnWidth?: number;
    rightColumnWidth?: number;
}

export default function IconBlockRegular({
    image,
    description,
    leftColumnWidth = 2,
    rightColumnWidth = 9,
}: IIconBlockRegular) {
    return (
        <Grid item lg={3} xs={12}>
            <Grid
                container
                alignItems={"start"}
                justifyContent={"center"}
                gap={4}
            >
                <Grid item justifyContent={"center"}>
                    <Image
                        style={{ maxWidth: "40px", height: "auto" }}
                        image={image}
                        alt={image.alternativeText}
                    />
                </Grid>
                <Grid item xs={7} md={rightColumnWidth}>
                    <RegularText
                        sx={{
                            "& p": {
                                margin: 0,
                                padding: 0,
                            },
                            "& strong": {
                                fontSize: { md: "1.875rem" },
                                fontWeight: 600,
                                color: colors.primary.main,
                                display: "block",
                                pb: 2,
                            },
                            fontSize: "1rem",
                        }}
                    >
                        <Markdown content={description} />
                    </RegularText>
                </Grid>
            </Grid>
        </Grid>
    );
}
