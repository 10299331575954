import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
import { Image, Markdown } from "..";

export interface IconBlockWithButtonProps {
    block: APITypes.Components.ContentBlocks.IconBlockWithButton;
}

export default function IconBlockWithButton({
    block,
}: IconBlockWithButtonProps) {
    return (
        <Box sx={{ backgroundColor: "#FFF" }} py={{ xs: 8, lg: 20 }}>
            <Container maxWidth={"xl"}>
                <Stack
                    justifyContent="center"
                    alignItems={"center"}
                    direction={"column"}
                    gap={8}
                >
                    <Typography variant="h2" align={"center"}>
                        {block.title}
                    </Typography>
                    <Stack
                        flexDirection={"row"}
                        flexWrap={"wrap"}
                        width={"100%"}
                        justifyContent={"center"}
                    >
                        {block.iconBlock.map((block, i) => {
                            return (
                                <Stack
                                    key={i}
                                    flexDirection={"row"}
                                    alignItems={"start"}
                                    gap={2}
                                    sx={{
                                        maxWidth: "360px",
                                    }}
                                >
                                    <Stack>
                                        <Image
                                            style={{
                                                maxWidth: "40px",
                                                maxHeight: "40px",
                                            }}
                                            image={block.image}
                                            alt={block.image?.name}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography
                                            component={"div"}
                                            variant="h3"
                                            sx={{
                                                "& p": {
                                                    margin: 0,
                                                },
                                            }}
                                        >
                                            <Markdown content={block.title} />
                                        </Typography>
                                        <Typography component={"div"}>
                                            <Markdown
                                                content={block.description}
                                            />
                                        </Typography>
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack>
                    <Stack>
                        <Button
                            variant="contained"
                            component={NextLink}
                            href={`${block.button.href}`}
                        >
                            {block.button.title}
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}
