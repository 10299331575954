import { colors } from "@components/Theme/colors";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Image, Markdown } from "components";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";

import { RotatingTitle } from "./RotatingTitle";

export interface RotatingTextBlockButtonProps {
    block: APITypes.Components.ContentBlocks.RotatingTitleBlockWithButton;
}

const RotatingTextBlockButton = ({ block }: RotatingTextBlockButtonProps) => {
    const {
        textColor,
        titleFixedPart,
        titleRotatingParts,
        description,
        button,
        image,
        subTitle,
    } = block;
    return (
        <Box
            sx={{
                backgroundColor: colors.white.light,
            }}
            py={14}
        >
            <Container maxWidth="xl">
                <Stack
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={4}
                >
                    <Stack sx={{ flex: 1, width: "100%" }}>
                        <Image
                            image={image}
                            alt={image.name}
                            layout="responsive"
                        />
                    </Stack>
                    <Stack sx={{ flex: 1, width: "100%" }}>
                        <Typography>{subTitle}</Typography>
                        <RotatingTitle
                            fixedTitle={titleFixedPart}
                            rotatingTitleParts={titleRotatingParts.map(
                                (rp) => ({
                                    text: rp.rotatingTitle,
                                    color: rp.textColor,
                                }),
                            )}
                        />
                        <Typography component={"div"}>
                            <Markdown content={description} />
                        </Typography>
                        {button && (
                            <Stack flexDirection={"row"}>
                                <Button variant="contained" size="large">
                                    <NextLink
                                        style={{
                                            textDecoration: "none",
                                            color: "#fff",
                                        }}
                                        href={button.href}
                                    >
                                        {button.title}
                                    </NextLink>
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};
export default RotatingTextBlockButton;
