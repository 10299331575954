import Head from "next/head";
import { APITypes } from "utils/api.types";

export interface MetaProps extends APITypes.Components.SEO {
    metaUrl?: string;
}

function Meta({
    metaTitle,
    metaDescription,
    metaImage,
    metaRobots,
    metaViewport,
    metaUrl,
    keywords,
}: MetaProps) {
    return (
        <Head>
            <title>{metaTitle}</title>
            <meta charSet="UTF-8" />
            <meta
                name="facebook-domain-verification"
                content="vr2tfzt8s9hj4pwjoa43ieelibhzkk"
            />
            {keywords && <meta name="keywords" content={keywords} />}
            {metaTitle && <meta name="title" content={metaTitle} />}
            {/* <meta name="viewport" content={metaViewport} /> */}
            <meta name="robots" content={metaRobots ?? "all"} />
            {metaDescription && (
                <meta name="description" content={metaDescription} />
            )}
            <meta property="og:type" content="website" />
            {metaTitle && <meta property="og:title" content={metaTitle} />}
            {metaImage && <meta property="og:image" content={metaImage?.url} />}
            {metaUrl && <meta property="og:url" content={metaUrl} />}
            {metaDescription && (
                <meta property="og:description" content={metaDescription} />
            )}
        </Head>
    );
}

export { Meta };
