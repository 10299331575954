import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
    checkboxFieldStyles,
    checkboxIconStyles,
} from "@components/blocks/LandingPageContactBlock/LandingPageContactForm.styles";
import { validationSchema } from "@components/blocks/LandingPageContactBlock/LandingPageContactForm.util";
import { Markdown } from "@components/Markdown";
import { colors } from "@components/Theme/colors";
import StopIcon from "@mui/icons-material/Stop";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    Stack,
    TextField,
    Typography,
    InputLabel,
} from "@mui/material";
import { Field, Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { APITypes } from "utils/api.types";
import { submitForm } from "utils/fetchingFunctions";

interface LandingPageContactFormProps {
    form: APITypes.Components.ContentBlocks.LandingPageContactFrom;
}

const LandingPageContactForm = ({ form }: LandingPageContactFormProps) => {
    const {
        namePlaceholder,
        emailAddressPlaceholder,
        submitButton,
        checkBox,
        messagePlaceholder,
    } = form;

    const [isFormSent, setFormSent] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
            privacy: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const res = await submitForm("Landing page form", values);
                if (res.status === 200) {
                    resetForm();
                    setFormSent(true);
                    (window as any).dataLayer.push({
                        event: "Landing page form sent",
                    });
                } else {
                    console.error(res);
                }
            } catch (error) {
                console.error(error);
            }
        },
    });

    if (!isMounted) {
        return null;
    }

    return (
        <div>
            {isFormSent ? (
                <Box mt={-2}>
                    <Typography variant="h2">
                        {submitButton.successMessage}
                    </Typography>
                    <Typography variant="h3">
                        {submitButton.successDescription}
                    </Typography>
                </Box>
            ) : (
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        school: "",
                        course: "",
                        track: "",
                    }}
                    onSubmit={() => {}}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container direction={"column"} spacing={1}>
                            <Grid item xs={12} md={6}>
                                <InputLabel>{namePlaceholder} *</InputLabel>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>
                                    {emailAddressPlaceholder} *
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel>{messagePlaceholder} *</InputLabel>
                                <TextField
                                    multiline={true}
                                    rows={4}
                                    fullWidth
                                    id="message"
                                    name="message"
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.message &&
                                        Boolean(formik.errors.message)
                                    }
                                    helperText={
                                        formik.touched.message &&
                                        formik.errors.message
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack flexDirection={"column"}>
                                    <Box>
                                        {checkBox.map((checkbox, i) => (
                                            <Box key={i}>
                                                <Field
                                                    sx={checkboxFieldStyles}
                                                    type="checkbox"
                                                    name={"privacy"}
                                                    as={FormControlLabel}
                                                    control={
                                                        <Checkbox
                                                            checkedIcon={
                                                                <StopIcon
                                                                    sx={checkboxIconStyles(
                                                                        form,
                                                                    )}
                                                                />
                                                            }
                                                        />
                                                    }
                                                    checked={
                                                        formik.values.privacy
                                                    }
                                                    label={
                                                        checkbox.label ? (
                                                            <Markdown
                                                                content={
                                                                    checkbox.label
                                                                }
                                                            />
                                                        ) : (
                                                            checkbox.Name
                                                        )
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                                <FormHelperText
                                                    sx={{
                                                        color: "red",
                                                        ml: "14px",
                                                    }}
                                                >
                                                    {formik.touched.privacy &&
                                                        formik.errors.privacy}
                                                </FormHelperText>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Stack flexDirection={"row"}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            color="green"
                                            endIcon={<ArrowForwardIcon />}
                                        >
                                            {form.submitButton.title}
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </form>
                </Formik>
            )}
        </div>
    );
};

export default LandingPageContactForm;
