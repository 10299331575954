import NextImage from "next/image";
import { APP_URLS } from "utils/fetchingFunctions";

interface ImageProps {
    className?: string;
    image?: any;
    layout?: "responsive" | "fill";
    alt?: string;
    style?: any;
    onLoad?: () => void;
}

const Image = ({
    className,
    image,
    layout,
    alt,
    style,
    onLoad,
}: ImageProps) => {
    if (!image) {
        return null;
    }

    const { url, width, height, alternativeText = "" } = image;

    const props = {
        className,
        src: `${APP_URLS.base}${url}`,
        alt: alt ?? alternativeText,
        width,
        height,
        layout,
        style,
        priority: true,
        onLoad,
    };

    if (!width || !height) {
        props.layout = "fill";
    }

    return <NextImage id={"customImage"} {...props} />;
};

export { Image };
