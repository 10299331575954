import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import TitleBig from "@components/typography/TitleBig";
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Stack,
} from "@mui/material";
import { Markdown } from "components";
import { APITypes } from "utils/api.types";
import { getBaseUrl } from "utils/fetchingFunctions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NextLink from "next/link";

export interface IImageWithTextBlockProps {
    block: APITypes.Components.ContentBlocks.ImageWithTextBlock;
}

const getPosition = (textBlockPosition: "left" | "center" | "right") => {
    switch (textBlockPosition) {
        case "left":
            return "start";
        case "center":
            return "center";
        case "right":
            return "end";
    }
};

const ImageWithTextBlock = ({ block }: IImageWithTextBlockProps) => {
    const {
        backgroundImage,
        title,
        backgroundColor,
        textBlockPosition,
        content,
        textblockBackgroundColor,
        textColor = "#000000",
        buttons,
    } = block;
    return (
        <Container maxWidth="xl" disableGutters sx={{ position: "relative" }}>
            <Box
                sx={{
                    backgroundColor: backgroundColor ?? "#F3F3F3",
                    paddingBottom: `${
                        (backgroundImage.height / backgroundImage.width) * 100
                    }%`,
                    maxHeight: "100%",
                    maxWidth: "100%",
                    backgroundImage: {
                        xs: `url(${getBaseUrl().base}${backgroundImage.url})`,
                        md: `url(${getBaseUrl().base}${backgroundImage.url})`,
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: { md: "absolute", xs: "relative" },
                    bottom: 0,
                    width: "100%",
                }}
            >
                <Container
                    maxWidth="lg"
                    disableGutters
                    sx={{
                        marginRight: { xs: 0, md: 2 },
                        marginLeft: { xs: 0, md: 2 },
                        display: "flex",
                        justifyContent: {
                            md: getPosition(textBlockPosition),
                            sm: "center",
                        },
                    }}
                >
                    <Card
                        sx={{
                            width: {
                                lg: "50%",
                                md: "60%",
                                sm: "100%",
                            },
                            background: colors.secondary.main,
                        }}
                    >
                        <Box
                            sx={{
                                padding: {
                                    lg: 4,
                                    xs: 2,
                                },
                            }}
                        >
                            <CardContent sx={{ color: colors.white.main }}>
                                <TitleBig
                                    text={title}
                                    style={{ color: colors.white.main }}
                                />
                                {content && (
                                    <RegularText
                                        sx={{
                                            "& a": {
                                                color: "white",
                                                textDecoration: "none",
                                            },
                                        }}
                                    >
                                        <Markdown content={content} />
                                    </RegularText>
                                )}
                                <Stack gap={2}>
                                    {buttons?.map((button, index) => (
                                        <Button
                                            component={NextLink}
                                            target="_blank"
                                            sx={{
                                                width: "fit-content",
                                            }}
                                            key={index}
                                            href={button.href}
                                            variant="contained"
                                            color="green"
                                            endIcon={<ArrowForwardIcon />}
                                        >
                                            {button.title}
                                        </Button>
                                    ))}
                                </Stack>
                            </CardContent>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </Container>
    );
};

export default ImageWithTextBlock;
