import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";
import { getBaseUrl } from "utils/fetchingFunctions";

export interface ClustersPreviewProps {
    block: APITypes.Components.ContentBlocks.ClustersPreview;
}

const ClustersPreview = ({ block }: ClustersPreviewProps) => {
    const { title, previewClusterCards } = block;

    return (
        <Box
            py={{ xs: 8, md: 14 }}
            sx={{
                backgroundColor: "#FAFAFA",
            }}
        >
            <Container maxWidth="xl">
                <Stack py={4}>
                    <Typography variant="h2">{title}</Typography>
                </Stack>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                >
                    {previewClusterCards.map((cluster, i) => (
                        <PreviewClusterCard key={i} cluster={cluster} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};
export default ClustersPreview;

interface PreviewClusterCardProps {
    cluster: APITypes.Components.PreviewClusterCard;
}

const PreviewClusterCard = ({ cluster }: PreviewClusterCardProps) => {
    return (
        <Grid
            container
            direction={"column"}
            item
            xs={12}
            md={3}
            component={NextLink}
            href={cluster.button.href}
            sx={{
                textDecoration: "none",
            }}
        >
            <Stack
                spacing={4}
                sx={{
                    height: "100%",
                    backgroundColor: "#FFF",
                }}
            >
                <Box
                    sx={{
                        height: { md: "340px", xs: "200px" },

                        backgroundColor: "black",
                        backgroundImage: {
                            xs: `url(${getBaseUrl().base}${
                                cluster.mobileImage.url
                            })`,
                            md: `url(${getBaseUrl().base}${
                                cluster.desktopImage.url
                            })`,
                        },
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center 10%",
                    }}
                />
                <Stack
                    spacing={2}
                    px={2}
                    sx={{
                        height: "100%",
                        flex: 1,
                    }}
                >
                    <Typography variant="h3">{cluster.title}</Typography>
                    <Typography color={"primary"}>
                        {cluster.description}
                    </Typography>
                </Stack>
                <Stack flexDirection={"row"} justifyContent={"end"}>
                    <Button
                        sx={{ px: 1, py: 0.5 }}
                        variant="contained"
                        component={NextLink}
                        href={cluster.button.href}
                    >
                        <ArrowForwardIcon
                            sx={{ p: 0, m: 0, width: "20px" }}
                            fontSize="small"
                        />
                    </Button>
                </Stack>
            </Stack>
        </Grid>
    );
};
