import * as Yup from "yup";

export const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
    message: Yup.string().required("Message is required"),
    privacy: Yup.bool()
        .oneOf([true], "Select is required")
        .required("Select is required")
}).required();
