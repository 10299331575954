import { colors } from "@components/Theme/colors";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Markdown } from "components";
import NextLink from "next/link";
import { APITypes } from "utils/api.types";

export interface TextBlockButtonProps {
    block: APITypes.Components.ContentBlocks.TextBlockButtonComponent;
}

const TextBlockButton = ({ block }: TextBlockButtonProps) => {
    const { textBlock, buttons } = block;

    return (
        <Box
            sx={{
                backgroundColor: colors.greyColor.main,
            }}
            py={14}
        >
            <Container
                maxWidth={"md"}
                sx={{
                    p: {
                        md: 0,
                    },
                }}
            >
                <Stack justifyContent={"center"} alignItems={"center"} gap={6}>
                    <Typography
                        variant="h3"
                        align="center"
                        sx={{
                            "& p": { m: 0 },
                        }}
                    >
                        {textBlock.subTitle}
                    </Typography>
                    <Typography
                        component={"div"}
                        variant="h2"
                        align="center"
                        sx={{
                            "& p": { m: 0 },
                        }}
                    >
                        <Markdown content={textBlock.title} />
                    </Typography>
                    <Typography
                        component={"div"}
                        align="center"
                        sx={{
                            "& p": { m: 0 },
                            color: colors.primary.main,
                        }}
                    >
                        <Markdown content={textBlock.description} />
                    </Typography>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        {buttons &&
                            buttons.map((currentButton, i) => {
                                return (
                                    <Button
                                        component={NextLink}
                                        key={i}
                                        variant="contained"
                                        size="large"
                                        color={"green"}
                                        href={currentButton.href}
                                    >
                                        {currentButton.title}
                                    </Button>
                                );
                            })}
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};
export default TextBlockButton;
