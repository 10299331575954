import { Box } from "@mui/material";
import { Meta, MetaProps } from "components";
import { ReactPortal } from "react";

interface LayoutProps {
    children:
        | React.ReactNode
        | React.ReactNode[]
        | boolean
        | ReactPortal
        | null;
    meta?: MetaProps;
}

const Layout = ({ children, meta }: LayoutProps) => {
    return (
        <div style={{ height: "100vh" }}>
            {meta && <Meta {...meta} />}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                {children}
            </Box>
        </div>
    );
};

export { Layout };
