import TitleBig from "@components/typography/TitleBig";
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import TextTransition, { presets } from "react-text-transition";

interface IRotatingTitleProps {
    fixedTitle: string;
    rotatingTitleParts: { text: string; color: string }[];
}

export const RotatingTitle = ({
    fixedTitle,
    rotatingTitleParts,
}: IRotatingTitleProps) => {
    const [index, setIndex] = useState(0);

    const [titles, colors] = useMemo(
        () => [
            rotatingTitleParts.map((rtp) => rtp.text),
            rotatingTitleParts.map((rtp) => rtp.color),
        ],
        [rotatingTitleParts],
    );
    useEffect(() => {
        const intervalId = setInterval(
            () =>
                setIndex((index) => {
                    let newIndex = index + 1;
                    if (newIndex === titles.length) newIndex = 0;
                    return newIndex;
                }),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, [titles]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "row",
                    xs: "column",
                },
                alignItems: {
                    lg: "center",
                    md: "center",
                    sm: "center",
                    xs: "left",
                },
            }}
        >
            <Box>
                <TitleBig text={fixedTitle} />
                <span>
                    <TextTransition
                        springConfig={presets.gentle}
                        className="big"
                        delay={300}
                        inline
                    >
                        <TitleBig
                            style={{ color: colors[index] ?? "black" }}
                            text={titles[index]}
                        />
                    </TextTransition>
                </span>
            </Box>
        </Box>
    );
};
