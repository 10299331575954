import { Box, Typography } from "@mui/material";

interface IFormSentMessage {
    submitButton: {
        successMessage: string;
        successDescription: string;
    };
}

export default function FormSentMessage({ submitButton }: IFormSentMessage) {
    return (
        <Box mt={-2}>
            <Typography
                sx={{
                    color: "white",
                    align: "left",
                    textAlign: "left",
                    fontSize: {
                        lg: "36px",
                        xs: "24px",
                    },
                    fontWeight: {
                        lg: 700,
                        xs: 600,
                    },
                    lineHeight: "48px",
                }}
            >
                {submitButton.successMessage}
            </Typography>
            <Typography
                sx={{
                    color: "white",
                    align: "left",
                    textAlign: "left",
                    fontSize: "24px",
                    fontWeight: 400,
                    lineHeight: "130%",
                }}
            >
                {submitButton.successDescription}
            </Typography>
        </Box>
    );
}
