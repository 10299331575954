import { BreadCrumbs } from "@components/BreadCrumbs/BreadCrumbs";
import SubTitleBiggest from "@components/typography/SubTitleBiggest";
import TitleBiggest from "@components/typography/TitleBiggest";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import { Image, Markdown } from "components";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { APITypes } from "utils/api.types";
import { fetchPositionsWithCluster } from "utils/fetchingFunctions";
import LandingPageHero from "./LandingPageHero/LandingPageHero";

export interface ClusterHeroProps {
    block: APITypes.Components.ContentBlocks.ClusterHero;
}

const ClusterHero = ({ block }: ClusterHeroProps) => {
    const router = useRouter();
    const { heroImage, heroSquareBlock } = block;

    const [positions, setPositions] = useState<APITypes.Components.Job[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetchPositionsWithCluster(
                router.locale,
                0,
                3,
                block.forCluster,
            );
            setPositions(res.data);
        };
        fetchData();
    }, [block.forCluster, router.locale]);

    return (
        <LandingPageHero block={block as any} />
        // <Box pb={12} sx={{ backgroundColor: "#F3F3F3" }}>
        //     <Box>
        //         <Image
        //             image={heroImage}
        //             alt={heroImage?.name}
        //             layout={"responsive"}
        //         />
        //     </Box>
        //     <Container maxWidth="lg">
        //         <Grid
        //             sx={{
        //                 marginTop: {
        //                     lg: "-280px",
        //                     md: "-160px",
        //                     sm: "-100px",
        //                     xs: "-60px",
        //                 },
        //                 width: "100%",
        //                 marginRight: "auto",
        //                 marginLeft: "auto",
        //             }}
        //             container
        //             direction="column"
        //             alignItems="center"
        //             justifyContent="center"
        //         >
        //             <Grid item xs={2}>
        //                 <Card
        //                     variant="outlined"
        //                     sx={{
        //                         boxShadow:
        //                             " 0px 22px 44px rgba(0, 0, 0, 0.07);",
        //                     }}
        //                 >
        //                     <Box
        //                         sx={{
        //                             margin: {
        //                                 lg: 6,
        //                                 xs: 2,
        //                             },
        //                         }}
        //                     >
        //                         <CardContent>
        //                             <Box sx={{ mb: "32px" }}>
        //                                 <BreadCrumbs />
        //                             </Box>
        //                             <TitleBiggest
        //                                 text={heroSquareBlock.title}
        //                                 titleSize={heroSquareBlock.titleSize}
        //                             />
        //                             <SubTitleBiggest
        //                                 text={heroSquareBlock.subTitle}
        //                             />
        //                             <Box sx={{ m: 6 }} />
        //                             <Typography
        //                                 component={"div"}
        //                                 sx={{
        //                                     fontSize: {
        //                                         lg: "21px",
        //                                         xs: "18px",
        //                                     },
        //                                     fontWeight: 400,
        //                                 }}
        //                             >
        //                                 <Markdown
        //                                     content={
        //                                         heroSquareBlock.description
        //                                     }
        //                                 />
        //                             </Typography>
        //                         </CardContent>
        //                         <Box sx={{ m: 4 }} />
        //                         {heroSquareBlock?.button &&
        //                             positions.length > 0 && (
        //                                 <CardActions
        //                                     sx={{
        //                                         justifyContent: {
        //                                             xs: "center",
        //                                             lg: "left",
        //                                         },
        //                                     }}
        //                                 >
        //                                     <Button
        //                                         variant="contained"
        //                                         size="large"
        //                                         sx={{
        //                                             fontSize: "21px",
        //                                             fontWeight: 700,
        //                                             textTransform: "none",
        //                                             "&.MuiButton-contained": {
        //                                                 backgroundColor:
        //                                                     heroSquareBlock
        //                                                         .button
        //                                                         .hexColor,
        //                                             },
        //                                             borderRadius: 0,
        //                                         }}
        //                                     >
        //                                         <Link
        //                                             href={
        //                                                 heroSquareBlock.button
        //                                                     .href
        //                                             }
        //                                             underline="none"
        //                                             sx={{
        //                                                 color: "#fff",
        //                                                 "&:hover": {
        //                                                     color: "#3EA4DC",
        //                                                 },
        //                                             }}
        //                                         >
        //                                             {
        //                                                 heroSquareBlock.button
        //                                                     .title
        //                                             }
        //                                         </Link>
        //                                     </Button>
        //                                 </CardActions>
        //                             )}
        //                     </Box>
        //                 </Card>
        //             </Grid>
        //         </Grid>
        //     </Container>
        // </Box>
    );
};
export default ClusterHero;
