import { colors } from "@components/Theme/colors";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    keyframes,
    Slider,
    Stack,
    Typography,
} from "@mui/material";
import { Image, Markdown } from "components";
import NextLink from "next/link";
import { useState } from "react";
import { APITypes } from "utils/api.types";

const spin = keyframes`
  from {
    height: 0px;
  }
`;

interface LandingPageHeroProps {
    block: APITypes.Components.ContentBlocks.LandingPageHero;
}

const LandingPageHero = ({ block }: LandingPageHeroProps) => {
    return (
        <Box
            pt={{ xs: 8, lg: 8 }}
            sx={{
                backgroundColor: colors.greyColor.light,
                display: "flex",
                alignItems: "end",
            }}
        >
            <Container maxWidth="xl" disableGutters>
                <Grid container alignItems="stretch">
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}
                    >
                        <Stack
                            justifyContent="center"
                            height={"100%"}
                            sx={{
                                maxWidth: { md: "calc(100% - 70px)" },
                                paddingLeft: { xs: "16px", md: "48px" },
                            }}
                        >
                            <Stack pb={4}>
                                <Typography variant="h1" color="primary">
                                    {block.heroSquareBlock.title}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    style={{
                                        color: colors.secondary.main,
                                    }}
                                >
                                    {block.heroSquareBlock.subTitle}
                                </Typography>
                                <Box py={2} />
                                <Typography
                                    component={"div"}
                                    sx={{
                                        fontSize: {
                                            lg: "18px",
                                            xs: "14px",
                                        },
                                        fontWeight: 400,
                                    }}
                                >
                                    <Markdown
                                        content={
                                            block.heroSquareBlock.description
                                        }
                                    />
                                </Typography>
                                {block.heroSquareBlock.button != null && (
                                    <Stack direction="row" py={2}>
                                        <Button
                                            component={NextLink}
                                            href={
                                                block.heroSquareBlock.button
                                                    .href
                                            }
                                            variant="contained"
                                            size="large"
                                        >
                                            {block.heroSquareBlock.button.title}
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                        <Stack
                            direction={"row"}
                            width={"100%"}
                            alignItems={"end"}
                            height={"81px"}
                        >
                            <Box
                                sx={{
                                    background: colors.primary.main,
                                    height: "81px",
                                    width: "100%",
                                    animation: `${spin} 2s ease`,
                                }}
                            />
                            <Box
                                sx={{
                                    background: colors.green.main,
                                    height: "81px",
                                    width: "100%",
                                    animation: `${spin} 2s ease`,
                                }}
                            />
                            <Box
                                sx={{
                                    background: colors.secondary.main,
                                    height: "40px",
                                    width: "100%",
                                    animation: `${spin} 2s ease`,
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Stack
                            direction={"row"}
                            alignItems={"end"}
                            sx={{
                                height: "100%",
                                minHeight: { xs: "100%", md: "498px" },
                            }}
                        >
                            <Image
                                style={{
                                    objectFit: "cover",
                                    height: "100%",
                                    width: "100%",
                                    alignSelf: "stretch",
                                }}
                                image={block.heroImage}
                                alt={block.heroImage.name}
                                layout="responsive"
                            />
                            <Box
                                sx={{
                                    background: "#E14C8B",
                                    height: "300px",
                                    width: "60px",
                                    flexShrink: 0,
                                    animation: `${spin} 2s ease`,
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default LandingPageHero;

interface QuizBlockProps {
    quizBlock: APITypes.Components.QuizBlock;
}

const SliderStyles = {
    "& .MuiSlider-thumb": {
        color: "white",
    },
    "& .MuiSlider-track": {
        color: "transparent",
        height: "0px",
    },
    "& .MuiSlider-rail": {
        color: "white",
        height: "2px",
    },
    "& .MuiSlider-active": {
        color: "white",
    },
    "& .MuiSlider-mark": {
        color: "white",
    },
};

function median(values: number[]) {
    // Never crash, just return something.
    if (values.length === 0) return 2;

    values.sort(function (a, b) {
        return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
}

const QuizBlock = ({ quizBlock }: QuizBlockProps) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [sliderValue, setSliderValue] = useState(0.5);
    const [allScores, setAllScores] = useState<number[]>([]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        if (!Array.isArray(newValue)) {
            setSliderValue(newValue);
        }
    };

    const handleNextStep = () => {
        if (sliderValue === 0 || sliderValue === 1) {
            const { negativeAnswer, positiveAnswer } =
                quizBlock.quizSteps[currentStep];
            const score =
                sliderValue < 0.5 ? negativeAnswer.score : positiveAnswer.score;
            if (currentStep + 1 === quizBlock.quizSteps.length) {
                setAllScores([]);
            } else {
                setAllScores([...allScores, score]);
            }

            const newIndex = (currentStep + 1) % quizBlock.quizSteps.length;
            setCurrentStep(newIndex);
            setSliderValue(0.5);
        }
    };

    const { question, negativeAnswer, positiveAnswer } =
        quizBlock.quizSteps[currentStep];

    const lastStep = currentStep + 1 === quizBlock.quizSteps.length;

    return (
        <Card
            variant="outlined"
            sx={{
                height: "100%",
                backgroundColor: "#ED816D",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",

                    margin: {
                        lg: 6,
                        xs: 2,
                    },
                }}
            >
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "16px",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                color: "white",
                                fontSize: {
                                    lg: "30px",
                                    xs: "24px",
                                },
                                fontWeight: 700,
                            }}
                        >
                            {quizBlock.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: "white",
                                fontSize: "24px",
                                fontWeight: 400,
                            }}
                        >
                            {currentStep + 1} / {quizBlock.quizSteps.length}
                        </Typography>
                    </Box>
                    {lastStep ? (
                        <>
                            {" "}
                            <Typography
                                align="center"
                                sx={{
                                    color: "white",
                                    fontSize: {
                                        lg: "22px",
                                        xs: "18px",
                                    },
                                    fontWeight: 700,
                                }}
                            >
                                {
                                    quizBlock.quizResults.find(
                                        (result) =>
                                            result.value === median(allScores),
                                    )?.title
                                }
                            </Typography>
                            <Typography
                                align="center"
                                sx={{
                                    color: "white",
                                    fontSize: {
                                        lg: "22px",
                                        xs: "18px",
                                    },
                                    fontWeight: 700,
                                }}
                            >
                                {
                                    quizBlock.quizResults.find(
                                        (result) =>
                                            result.value === median(allScores),
                                    )?.description
                                }
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Box>
                                <Typography
                                    align="center"
                                    sx={{
                                        color: "white",
                                        fontSize: {
                                            lg: "30px",
                                            xs: "24px",
                                        },
                                        fontWeight: 700,
                                    }}
                                >
                                    {question}
                                </Typography>
                            </Box>
                            <Box mt={4}>
                                <Grid container direction={"row"} spacing={4}>
                                    <Grid item xs={4}>
                                        <Typography
                                            align="center"
                                            sx={{
                                                color: "white",
                                                fontSize: {
                                                    lg: "22px",
                                                    xs: "18px",
                                                },
                                                fontWeight: 700,
                                            }}
                                        >
                                            {negativeAnswer.answer}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Slider
                                            sx={SliderStyles}
                                            defaultValue={0.5}
                                            value={sliderValue}
                                            min={0}
                                            max={1}
                                            marks={[
                                                {
                                                    value: negativeAnswer.score,
                                                    label: undefined,
                                                },
                                                {
                                                    value: positiveAnswer.score,
                                                    label: undefined,
                                                },
                                            ]}
                                            step={1}
                                            onChange={handleSliderChange}
                                            aria-labelledby="slider"
                                            valueLabelDisplay="off"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            align="center"
                                            sx={{
                                                color: "white",
                                                fontSize: {
                                                    lg: "22px",
                                                    xs: "18px",
                                                },
                                                fontWeight: 700,
                                            }}
                                        >
                                            {positiveAnswer.answer}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                </CardContent>

                <CardActions
                    sx={{
                        mt: "auto",
                        justifyContent: "center",
                    }}
                >
                    {lastStep ? (
                        <Button
                            variant="contained"
                            size="large"
                            sx={{
                                fontSize: "21px",
                                fontWeight: 700,
                                textTransform: "none",
                                "&.MuiButton-contained": {
                                    backgroundColor: quizBlock.button.hexColor,
                                },
                                "&.Mui-disabled": {
                                    color: "gray",
                                },
                                borderRadius: 0,
                            }}
                        >
                            <NextLink
                                href={quizBlock.button.href}
                                style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                }}
                            >
                                {quizBlock.button.subTitle}
                            </NextLink>
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            size="large"
                            disabled={sliderValue !== 1 && sliderValue !== 0}
                            sx={{
                                fontSize: "21px",
                                fontWeight: 700,
                                textTransform: "none",
                                "&.MuiButton-contained": {
                                    backgroundColor: quizBlock.button.hexColor,
                                },
                                "&.Mui-disabled": {
                                    color: "gray",
                                },
                                borderRadius: 0,
                            }}
                            onClick={handleNextStep}
                        >
                            {quizBlock.button.title}
                        </Button>
                    )}
                </CardActions>
            </Box>
        </Card>
    );
};
