import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput,
    FormControlLabel,
    Stack,
} from "@mui/material";
import { colors } from "@components/Theme/colors";

interface IFilter {
    title: string;
    options: { title: string; value: string }[];
    checkedOptions: string[];
    handleChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        filterField: string,
    ) => void;
    filterField: string;
}

const Filter: React.FC<IFilter> = ({
    title,
    options,
    checkedOptions,
    handleChange,
    filterField,
}) => {
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    return (
        <FormControl fullWidth sx={{ width: "100%" }}>
            <Select
                variant="standard"
                multiple
                value={[title]}
                input={<OutlinedInput />}
                renderValue={(selected) => title}
                MenuProps={MenuProps}
                style={{
                    border: 0,
                    color: colors.primary.main,
                }}
            >
                <Stack px={2}>
                    {options.map((option, i) => (
                        <FormControlLabel
                            key={i}
                            control={
                                <Checkbox
                                    onChange={(event) => {
                                        handleChange(event, filterField);
                                    }}
                                    checked={checkedOptions.includes(
                                        option.value,
                                    )}
                                    value={option.value}
                                />
                            }
                            label={option.title}
                        />
                    ))}
                </Stack>
            </Select>
        </FormControl>
    );
};

export default Filter;
