import { Typography } from "@mui/material";

export interface IRegularText {
    text?: string;
    sx?: Record<string, any>;
    children?: any;
}

export default function RegularText({ text, sx, children }: IRegularText) {
    return (
        <Typography
            component={text ? "p" : "div"}
            sx={{
                ...sx,
            }}
        >
            {text ?? children}
        </Typography>
    );
}
