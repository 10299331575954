import { colors } from "@components/Theme/colors";
import { Box, Container, Grid, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";

interface VacatureDetailDescriptionBlockProps {
    block: APITypes.Components.ContentBlocks.VacatureDetail;
    position: APITypes.Components.Job;
}

export const SPONTANEOUS_APPLICATION = "a0tw0000008JS0CAAW";
export const INTERNSHIP_NL = "a0tw0000004PadFAAS";
export const INTERNSHIP_FR = "a0t6900000CHn9MAAT";
export const CONSULT_NL = "a0t9N000001MIK5QAO";
export const CONSULT_FR = "a0t9N000001Nt0VQAS";

const VacatureDetailDescriptionBlock = ({
    block,
    position,
}: VacatureDetailDescriptionBlockProps) => {
    if (SPONTANEOUS_APPLICATION.includes(position.jobsId)) return null;
    return (
        <Box py={10} sx={{ backgroundColor: colors.greyColor.main }}>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{ minHeight: "764px" }}>
                        <Box
                            p={4}
                            sx={{
                                backgroundColor: colors.greyColor.light,
                                height: "100%",
                            }}
                        >
                            <Grid item xs={8}>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: { xs: "1.5rem", md: "2rem" },
                                    }}
                                    style={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {block.functionDescriptionTitle}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: { xs: "1.5rem", md: "2rem" },
                                    }}
                                    style={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {position.title}
                                </Typography>
                            </Grid>
                            <Typography
                                component={"div"}
                                sx={{
                                    fontSize: "16px",
                                    ["ul"]: {
                                        paddingLeft: { xs: "0", md: "40px" },
                                    },
                                    fontWeight: 400,
                                }}
                            >
                                <Box
                                    sx={{
                                        ["ul"]: {
                                            paddingLeft: {
                                                xs: "0",
                                                md: "10px",
                                            },
                                        },
                                        "& li::marker": {
                                            content: "'━'",
                                            color: colors.secondary.main,
                                        },
                                        "& li": {
                                            pl: "10px",
                                            pb: "20px",
                                        },
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: position.jobDescription,
                                    }}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ minHeight: "764px" }}>
                        <Box
                            p={4}
                            sx={{
                                display: "flex",
                                alignItems: "start",
                                backgroundColor: colors.greyColor.light,
                                height: "100%",
                            }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    fontSize: {
                                        lg: "21px",
                                        xs: "18px",
                                    },
                                    fontWeight: 400,
                                }}
                            >
                                <Typography
                                    align="left"
                                    variant="h2"
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: { xs: "1.5rem", md: "2rem" },
                                    }}
                                    style={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {block.functionRequirementsTitle}
                                </Typography>
                                <Box
                                    sx={{
                                        fontSize: "16px",
                                        ["ul"]: {
                                            paddingLeft: {
                                                xs: "0",
                                                md: "10px",
                                            },
                                            "& li::marker": {
                                                content: "'━'",
                                                color: colors.secondary.main,
                                            },
                                            "& li": {
                                                pl: "10px",
                                                pb: "20px",
                                            },
                                        },
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: position.jobRequirements,
                                    }}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default VacatureDetailDescriptionBlock;
