import { Stack } from "@mui/material";
import { APITypes } from "utils/api.types";
import YouTube from "react-youtube";

enum EVideoProvider {
    Vimeo = "vimeo",
    Youtube = "youtube",
    Facebook = "facebook",
}

const VideoItem = ({
    video,
    setPlayerPlayVideo,
    setIsHideBox,
}: {
    video: APITypes.Components.IVideo;
    setPlayerPlayVideo: (e: any) => void;
    setIsHideBox: (isHideBox: boolean) => void;
}) => {
    const { provider, providerUid } = video;

    const videoOnReady = (event: any) => {
        // Access the player through event.target
        setPlayerPlayVideo(() => event.target);
    };

    return (
        <Stack justifyContent={"center"}>
            {provider === EVideoProvider.Vimeo && (
                <iframe
                    src={`https://player.vimeo.com/video/${providerUid}`}
                    frameBorder={0}
                    allowFullScreen
                    style={{ width: "100%", aspectRatio: "16/9" }}
                />
            )}
            {provider === EVideoProvider.Youtube && (
                // <iframe
                //     src={`https://www.youtube-nocookie.com/embed/${providerUid}?controls=0&showinfo=0&rel=0&modestbranding=1`}
                //     frameBorder={0}
                //     allowFullScreen
                //     style={{ width: "100%", aspectRatio: "16/9" }}
                // />
                <YouTube
                    className="video"
                    onReady={videoOnReady}
                    videoId={providerUid}
                    opts={{
                        width: "100%",
                        height: "100%",
                        playerVars: {
                            controls: 0,
                            rel: 0,
                            showinfo: 0,
                            modestbranding: 1,
                        },
                    }}
                    style={{ width: "100%", aspectRatio: "16/9" }}
                    onPlay={() => {
                        setIsHideBox(true);
                    }}
                    onPause={() => {
                        setIsHideBox(false);
                    }}
                />
            )}
            {provider === EVideoProvider.Facebook && (
                <iframe
                    src={`https://www.facebook.com/plugins/video.php?href=${providerUid}&show_text=false&t=0`}
                    frameBorder="0"
                    style={{ width: "100%", aspectRatio: "16/9" }}
                    allowFullScreen
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
            )}
        </Stack>
    );
};

export default VideoItem;
