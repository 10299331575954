import { colors } from "@components/Theme/colors";
import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { Image, Markdown } from "components";
import { APITypes } from "utils/api.types";

export interface QuoteBlockProps {
    block: APITypes.Components.ContentBlocks.QuoteBlock;
}

const QuoteBlock = ({ block }: QuoteBlockProps) => {
    const {
        author,
        quote,
        hexTextColor,
        hexBackgroundColor,
        backgroundImage,
        position,
    } = block;

    return (
        <Box
            py={20}
            sx={{
                backgroundColor: colors.white.main,
            }}
        >
            {backgroundImage && (
                <Box>
                    <Image
                        image={backgroundImage}
                        alt={backgroundImage?.name}
                        layout={"responsive"}
                    />
                </Box>
            )}
            <Container maxWidth="lg" sx={{ mt: backgroundImage ? -10 : 0 }}>
                <Stack
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={8}
                >
                    <Stack
                        sx={{
                            flex: 1,
                            width: "100%",
                        }}
                    >
                        <Typography
                            component={"div"}
                            variant="h2"
                            sx={{ textAlign: { xs: "center", lg: "right" } }}
                        >
                            <Markdown content={author} />
                        </Typography>
                    </Stack>
                    <Stack
                        sx={{
                            flex: 1,
                            width: "100%",
                        }}
                    >
                        <Paper
                            sx={{
                                backgroundColor: colors.greyColor.light,
                                color: colors.primary.main,
                                padding: "40px",
                                border: "none",
                                boxShadow: "none",
                                display: "flex",
                            }}
                        >
                            <Stack gap={4}>
                                <Typography
                                    align="center"
                                    sx={{
                                        "& .quotes": {
                                            color: colors.pink.main,
                                        },
                                        fontFamily: "Goodall Pro",
                                        fontSize: {
                                            lg: "36px",
                                            xs: "24px",
                                        },
                                        lineHeight: "38px",
                                        fontWeight: 400,
                                    }}
                                >
                                    <span className="quotes">&quot;</span>
                                    {quote}
                                    <span className="quotes">&quot;</span>
                                </Typography>
                                <Typography
                                    align="center"
                                    component={"div"}
                                    sx={{
                                        color: colors.pink.main,
                                    }}
                                >
                                    <Markdown content={`── ${position}`} />
                                </Typography>
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};
export default QuoteBlock;
