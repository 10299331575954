import { colors } from "@components/Theme/colors";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Image, Markdown } from "components";
import Carousel from "react-material-ui-carousel";
import { APITypes } from "utils/api.types";

export interface SubTrajectITClusterProps {
    block: APITypes.Components.ContentBlocks.SubTrajectITCluster;
}

const SubTrajectITCluster = ({ block }: SubTrajectITClusterProps) => {
    const { title, description, iconBlock, hexBackgroundColor, hexTextColor } =
        block;

    return (
        <Box
            sx={{ backgroundColor: colors.white.main, color: hexTextColor }}
            py={{ xs: 8, lg: 20 }}
            pb={{ xs: 8, lg: 30 }}
        >
            <Container maxWidth="xl">
                <Stack
                    justifyContent="center"
                    alignItems={"center"}
                    direction={"column"}
                    sx={{ display: { xs: "none", lg: "flex" } }}
                >
                    <Typography variant="h2" align={"center"}>
                        {block.title}
                    </Typography>
                    <Typography align="center" variant="h2">
                        {description}
                    </Typography>
                    <Stack
                        flexWrap={"wrap"}
                        width={"100%"}
                        justifyContent={"center"}
                        alignItems={"start"}
                        gap={8}
                        sx={{
                            pt: { xs: 4, lg: 8 },
                            flexDirection: {
                                xs: "column",
                                lg: "row",
                            },
                        }}
                    >
                        {iconBlock.map((block, i) => (
                            <Stack
                                key={i}
                                flexDirection={"row"}
                                alignItems={"start"}
                                justifyContent={"start"}
                                gap={"24px"}
                                sx={{
                                    maxWidth: "360px",
                                    flex: "1 1 30%",
                                }}
                            >
                                <Typography align="center">
                                    <Image
                                        image={block.image}
                                        alt={block.image?.name}
                                    />
                                </Typography>
                                <Typography
                                    align="left"
                                    component={"div"}
                                    sx={{
                                        "& p": {
                                            margin: 0,
                                            padding: 0,
                                        },
                                        "& strong": {
                                            fontSize: { md: "1.875rem" },
                                            fontWeight: 600,
                                            color: colors.primary.main,
                                            display: "block",
                                            pb: "20px",
                                        },
                                        fontSize: "1rem",
                                    }}
                                >
                                    <Markdown content={block.description} />
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    display={"flex"}
                    sx={{
                        flexDirection: {
                            xs: "column",
                            lg: "row",
                        },
                    }}
                    spacing={12}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: {
                                xs: "block",
                                lg: "none",
                            },
                        }}
                    >
                        <Carousel
                            autoPlay={false}
                            navButtonsAlwaysInvisible={true}
                            indicatorIconButtonProps={{
                                style: {
                                    padding: "20px",
                                    color: "grey",
                                },
                            }}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    color: "#E6539B",
                                },
                            }}
                            navButtonsProps={{
                                style: {
                                    borderRadius: 0,
                                    backgroundColor: "transparent",
                                    color: "black",
                                },
                            }}
                        >
                            {iconBlock.map((block, i) => (
                                <Grid item xs={12} key={i}>
                                    <Grid item xs={12}>
                                        <Typography align="center">
                                            <Image
                                                image={block.image}
                                                alt={block.image?.name}
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ height: "160px" }}>
                                        <Typography
                                            component={"div"}
                                            align="center"
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: {
                                                    xs: 400,
                                                },
                                            }}
                                        >
                                            <Markdown
                                                content={block.description}
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Carousel>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default SubTrajectITCluster;
