import { colors } from "@components/Theme/colors";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Markdown } from "components";
import { APITypes } from "utils/api.types";
import { SanitizeString } from "utils/SanitizeString";

export interface VisionBlockProps {
    block: APITypes.Components.ContentBlocks.VisionBlock;
}

const VisionBlock = ({ block }: VisionBlockProps) => {
    const { title, description } = block;

    return (
        <Box
            sx={{
                position: "relative",
                backgroundColor: colors.greyColor.main,
            }}
            py={{ xs: 6, md: 14 }}
        >
            <Stack
                flexDirection={"row"}
                sx={{
                    zIndex: 1,
                    display: { xs: "none", lg: "flex" },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: "100%",
                }}
            >
                <svg
                    width="105"
                    viewBox="-1 0 105 620"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0 0L39.9953 630H104.161L64.0575 0H0Z"
                        fill="#E14C8B"
                    />
                </svg>
                <svg
                    width="140"
                    viewBox="0 0 140 630"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.405273 0L40.4006 630H139.251L99.2554 0H0.405273Z"
                        fill="#13E0B9"
                    />
                </svg>
                <svg
                    width="123"
                    viewBox="0 0 123 630"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.603516 0L40.5988 630H175L134.897 0H0.603516Z"
                        fill="#026CFF"
                    />
                </svg>
            </Stack>

            <Container maxWidth="sm" disableGutters>
                <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={4}
                    sx={{ position: "relative", zIndex: 1 }}
                >
                    <Stack>
                        <Typography
                            component={"div"}
                            variant="h2"
                            align="center"
                            sx={{
                                "& p": {
                                    margin: "0",
                                },
                            }}
                        >
                            <SanitizeString content={title} />
                        </Typography>
                    </Stack>
                    <Stack>
                        <Typography
                            align="center"
                            component={"div"}
                            sx={{
                                "& p": {
                                    margin: "0",
                                },
                            }}
                        >
                            <SanitizeString content={description} />
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};
export default VisionBlock;
