import { sanitize } from "dompurify";
import { useEffect, useState } from "react";
import markdownToHtml from "utils/markdownToHtml";

type MarkdownProps = {
    className?: string;
    content: string;
    isOpenLinkNewTab?: boolean;
};

const Markdown = ({
    className = "",
    content,
    isOpenLinkNewTab = true,
}: MarkdownProps) => {
    const [parsedContent, setParsedContent] = useState("");

    useEffect(() => {
        const parse = async () => {
            const unsafeContent = await markdownToHtml(content);

            const safeContent = sanitize(unsafeContent, {
                ALLOWED_TAGS: [
                    "ul",
                    "li",
                    "strong",
                    "a",
                    "br",
                    "p",
                    "h1",
                    "h2",
                    "h3",
                    "em",
                ],
                ADD_ATTR: ["target"],
            });

            let modifiedContent = safeContent;

            if (isOpenLinkNewTab) {
                modifiedContent = safeContent.replaceAll(
                    "<a",
                    `<a target="_blank"`,
                );
            }

            setParsedContent(modifiedContent);
        };

        parse();
    }, [content, isOpenLinkNewTab]);

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: parsedContent,
            }}
        />
    );
};

export { Markdown };
