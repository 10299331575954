import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Image } from "components";
import { APITypes } from "utils/api.types";
import NextLink from "next/link";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { colors } from "@components/Theme/colors";

export interface InternshipNewsBlockProps {
    block: APITypes.Components.ContentBlocks.InternshipNews;
}

const InternshipNewsBlock = ({ block }: InternshipNewsBlockProps) => {
    const { backgroundImage, title, button } = block;

    return (
        <Container maxWidth={"xl"}>
            <Box>
                <Box style={{ position: "relative" }}>
                    <Box>
                        <Image
                            style={{
                                minHeight: 250,
                                maxHeight: "100%",
                                objectFit: "cover",
                                width: "auto",
                            }}
                            image={backgroundImage}
                            alt={backgroundImage?.name}
                            layout="responsive"
                        />
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: colors.secondary.main,
                            overflow: "hidden",
                            width: { xs: "100%", lg: "600px" },
                            left: {
                                xs: "0",
                                lg: "calc(5% - 54px)",
                            },
                            top: {
                                xs: "0",
                                lg: "calc(21% - 54px)",
                            },
                            bottom: {
                                xs: "0",
                                lg: "auto",
                            },
                            position: { xs: "relative", lg: "absolute" },
                            color: "white",
                            padding: "50px",
                        }}
                    >
                        <Stack gap={4}>
                            <Typography
                                variant="h2"
                                style={{ color: colors.white.main }}
                            >
                                {title}
                            </Typography>
                            <Stack flexDirection={"row"}>
                                <Button
                                    variant="contained"
                                    component={NextLink}
                                    color="green"
                                    href={button.href}
                                    endIcon={<ArrowForwardIcon />}
                                >
                                    {button.title}
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
export default InternshipNewsBlock;
