import { Markdown } from "@components/Markdown";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";
import ContactForm from "./ContactForm";
import { colors } from "@components/Theme/colors";

export interface ContactITClusterProps {
    block: APITypes.Components.ContentBlocks.ContactITCluster;
}

const ContactITCluster = ({ block }: ContactITClusterProps) => {
    const { formDescription, form } = block;

    return (
        <Box
            id="form"
            sx={{
                backgroundColor: colors.greyColor.light,
            }}
            py={{ xs: 8, lg: 14 }}
        >
            <Container maxWidth="lg">
                <Stack sx={{ width: { xs: "100%", lg: "50%" } }} gap={4}>
                    <Typography variant="h2">
                        {formDescription.title}
                    </Typography>
                    <Typography component={"div"}>
                        <Markdown content={formDescription.description} />
                    </Typography>
                </Stack>
                <Grid item xs={12} md={6} mt={2}>
                    <ContactForm form={form} />
                </Grid>
            </Container>
        </Box>
    );
};
export default ContactITCluster;
