import { colors } from "@components/Theme/colors";
import RegularText from "@components/typography/RegularText";
import { Box, Container, Paper, Typography } from "@mui/material";
import { APITypes } from "utils/api.types";

export interface IQuoteWithPicture {
    block: APITypes.Components.ContentBlocks.QuoteWithPicture;
}

export default function QuoteWithPicture({ block }: IQuoteWithPicture) {
    const { image, quote, backgroundColor, personName, textColor } = block;

    const quoteWithoutQuotationMarks = quote.replace(/\"/g, "");
    return (
        <Box
            pt={5}
            pb={5}
            sx={{
                backgroundColor: backgroundColor,
            }}
        >
            <Container
                disableGutters
                maxWidth={false}
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: {
                        lg: "row",
                        sm: "column",
                        xs: "column",
                    },
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: backgroundColor,
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        marginRight: {
                            lg: "32px",
                            sm: "0px",
                            xs: "0px",
                        },
                        marginBottom: {
                            lg: "0px",
                            sm: "24px",
                            xs: "24px",
                        },
                        maxWidth: "500px",
                        display: "flex",
                        flexDirection: {
                            lg: "row",
                            sm: "column",
                            xs: "column",
                        },
                        alignItems: "center",
                        justifyItems: "center",
                    }}
                ></Box>
                <Box
                    sx={{
                        height: "100%",
                        maxWidth: "500px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Paper
                        sx={{
                            height: "100%",
                            backgroundColor: colors.white.main,
                            color: colors.primary.main,
                            padding: "40px",
                            border: "none",
                            boxShadow: "none",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            gap: 4,
                        }}
                    >
                        <Typography
                            align="center"
                            sx={{
                                "& .quotes": {
                                    color: colors.pink.main,
                                },
                                fontFamily: "Goodall Pro",
                                fontSize: {
                                    lg: "36px",
                                    xs: "24px",
                                },
                                lineHeight: "38px",
                                fontWeight: 400,
                            }}
                        >
                            <span className="quotes">&quot;</span>
                            {quoteWithoutQuotationMarks}
                            <span className="quotes">&quot;</span>
                        </Typography>
                        <RegularText
                            sx={{
                                color: colors.pink.main,
                                fontSize: "14px",
                            }}
                            text={`──  ${personName}`}
                        />
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
}
